<template>
  <builder-block :data="data" :options="options" :context="context">
    <div class="text-content flex flex-col">
      <!-- <div>{{ isOverflow }} {{ context.isLast }} {{ context.path }}</div> -->
      <div v-if="options.text" v-html="formatText(translate(options.text))"></div>
      <template v-for="key in options.values">
        <slot :name="key" :k="key" :value="translate(data[key])" :formattedValue="formatText(translate(data[key]))">
          <div
            class="default-html whitespace-pre-line text-justify leading-tight tracking-normal"
            :class="options?.class"
            v-html="formatText(translate(data[key]))"
            :key="key"
          ></div>
        </slot>
      </template>
    </div>
  </builder-block>
</template>

<script setup lang="ts">
import { marked } from 'marked'
import { text as api } from '../composables/builderOptions'

const { data, options, context } = defineProps(['data', 'options', 'context'])

function translate(value: string) {
  return context.translations[value] || value
}

function formatText(value: string | number) {
  if (typeof value !== 'string') {
    return value
  }

  return marked(value, { breaks: true })
}
</script>
<script lang="ts">
export default {
  api,
  styles: {
    '.text-content': '',
  },
  story: {
    props: {
      options: {
        text: `This is a HTML bloc of text, with a <strong>bold</strong> word and a <em>italic</em> word.<br>
        <span>This line is wrapped by the span tag.</span>
        `,
      },
      context: {
        translations: {},
      },
    },
  },
}
</script>
<style scoped>
.default-html >>> h1 {
  font-size: 32px !important;
  font-weight: bold !important;
}
.default-html >>> h2 {
  font-size: 24px !important;
  font-weight: bold !important;
}
.default-html >>> h3 {
  font-size: 20.8px !important;
  font-weight: bold !important;
}
.default-html >>> h4 {
  font-size: 16px !important;
  font-weight: bold !important;
}
.default-html >>> h5 {
  font-size: 13.33px !important;
  font-weight: bold !important;
}
.default-html >>> h6 {
  font-size: 10.67px !important;
  font-weight: bold !important;
}
.default-html >>> ul {
  padding-left: 40px !important;
  list-style-type: disc !important;
  line-height: 0.8rem !important;
}
.default-html >>> ol {
  padding-left: 40px !important;
  list-style-type: decimal !important;
  line-height: 0.8rem !important;
}
.default-html >>> li {
  display: list-item !important;
  list-style-position: outside !important;
}
</style>
