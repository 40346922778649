<style scoped>
.data-report-run .buttons button {
  margin-right: 5px;
}

.data-report-run .variables {
  display: flex;
  flex-direction: row;
}

.data-report-run .url-block .url-container {
  display: flex;
  width: 100%;
  margin-bottom: 14px;
  margin-top: 20px;
}

.data-report-run .url-block .url-container input {
  width: 100%;
  max-width: 100%;
  flex: auto;
}

.data-report-run .url-block .url-container .actions {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.data-report-run .url-block .url-container .actions svg {
  width: 16px;
  height: 16px;
}

.data-report-run .result {
  margin-top: 4px;
  position: relative;
  display: flex;
  flex: auto;
  flex-direction: column;
}

.data-report-run button svg {
  height: 16px;
  width: 16px;
}

:deep() .dr-variable-input {
  margin-right: 10px;
}
</style>

<template lang="pug">
loader(v-if="loading")
.block.expand.data-report-run(v-else)
  .row
    h2 {{ t.variables || 'Variables' }}
  .row.variables(v-if="variablesInputs && variablesInputs.length")
    dr-variable-input(
      v-for="param in variablesInputs"
      :variableDefinition="param"
      :inputData="drInputData?.[param.name]"
      :missingDependencies="drMissingDependencies[param.name]"
      :value="drVariables[param.name]"
      @update:value="value => drUpdateVariable(param, value)"
      :key="param.name"
    )
    .row
      label {{ t.data_report_run.force_calculate }}
      input(type="checkbox" v-model="forceCalculate")
  .url-block
    .url-container
      input(readonly="readonly" ref="url" :value="url.replace(/.token=.*/, '')" aria-label="URL")
      .actions
        button.ghost(tt="Copy" @click="copy()" aria-label="Copy")
          svg-icon(name="ic_file_copy")
        button(tt="Run" @click="test" aria-label="Run")
          svg-icon(name="pt-icon-play")
    .row.result.expand
      .column.expand(v-if="result")
        label {{ t.result || 'result' }} {{ fromDataReportResultText }}
        code-editor(:code="result" language="json" max-height="calc(100vh - 460px)" @update="handleChangeResult")
    .row(v-if="result && !isNaN(this.id)")
      button.primary.ghost(@click="saveResult") {{ saveButtonText }}
</template>

<script>
import dataReportService from '@100-m/hauru/src/services/DataReportService'
import { useDrVariables } from '@100-m/hauru/src/components/form/drVariables/lib'
import DrVariableInput from '@100-m/hauru/src/components/form/drVariables/drVariableInput.vue'
import dataReportResultService from '@100-m/hauru/src/services/DataReportResultService'

export const additions = {}
export default {
  props: ['id'],
  components: { DrVariableInput },
  setup(props) {
    const {
      variables: drVariables,
      updateVariable: drUpdateVariable,
      partials: drPartials,
      inputData: drInputData,
      missingDependencies: drMissingDependencies,
      initDrVariables,
      runParameters: drRunParameters,
    } = useDrVariables()

    return {
      id: props.id,
      drVariables,
      drUpdateVariable,
      drPartials,
      drInputData,
      drMissingDependencies,
      initDrVariables,
      drRunParameters,
    }
  },
  data() {
    return {
      loading: true,
      dr: null,
      name: '',
      result: null,
      dataReportResultId: null,
      forceCalculate: false,
    }
  },
  async mounted() {
    await this.init()
  },
  methods: {
    async copy() {
      await navigator.clipboard.writeText(this.url)
    },
    async init() {
      this.loading = true
      this.dr = await this.getQuery(this.id)
      this.name = this.dr.name
      await this.initDrVariables(this.dr.settingVariableParameters || [], this.dr.variables)
      this.loading = false
    },
    async getQuery(id) {
      const _id = isNaN(parseInt(id)) ? id : parseInt(id)
      return dataReportService.getById(_id)
    },

    async test() {
      this.result = null
      this.dataReportResultId = null
      const runVariables = await this.drVariables
      const r = await dataReportService.run(this.id, runVariables, { forceCalculate: this.forceCalculate })

      const resultRaw = r?.data?.result || r?.error || {}

      this.dataReportResultId = r?.data?.id || null // If data-report-result id is returned, it means that the result was saved
      this.result = JSON.stringify(resultRaw, null, 2)
    },
    handleChangeResult(result) {
      this.result = result
    },
    async saveResult() {
      if (!this.result) {
        return $root.toast({ description: $root.t.data_report_run.error_no_result, type: 'error', timeout: 5000 })
      }

      const runVariables = await this.drVariables
      let result = {}

      try {
        result = JSON.parse(this.result || '{}')
      } catch (e) {
        return $root.toast({
          description: $root.t.data_report_run.error_json_malformed,
          type: 'error',
          timeout: 5000,
        })
      }

      const existingDataReportResult = await dataReportResultService.findFirst({
        dataReportDefinitionId: Number(this.id),
        runVariables,
      })

      if (existingDataReportResult) {
        await dataReportResultService.update({
          id: existingDataReportResult.id,
          dataReportResultInput: {
            result,
            metaData: { origin: 'computed' },
          },
        })
      } else {
        await dataReportResultService.create({
          dataReportResultInput: {
            dataReportDefinitionId: Number(this.id),
            runVariables,
            result,
            metaData: { origin: 'computed' },
          },
        })
      }

      $root.toast({
        description: this.dataReportResultId
          ? $root.t.data_report_results.update_data_report_result_success
          : $root.t.data_report_results.create_data_report_result_success,
        type: 'success',
        timeout: 5000,
      })
    },
  },
  computed: {
    variablesInputs() {
      return this.drPartials.concat(this.drRunParameters)
    },
    url() {
      const baseUrl = config.graphqlEndpoint.includes('localhost')
        ? ''
        : `${window.location.protocol}//${window.location.host}`
      return `${baseUrl}${config.graphqlEndpoint}/data-reports/${this.id}/run${this.varUrl}${
        this.varUrl === '' ? `?` : `&`
      }token=${$root?.profile?.idToken}${this.forceCalculate ? '&forceCalculate=true' : ''}`
    },
    varUrl() {
      if (!this.drVariables) {
        return ''
      }

      return '?runVariables=' + encodeURIComponent(JSON.stringify(this.drVariables))
    },
    fromDataReportResultText() {
      return this.dataReportResultId
        ? `${$root.t.data_report_run.from_data_report_result} ${this.dataReportResultId}`
        : ''
    },
    saveButtonText() {
      return this.dataReportResultId ? $root.t.data_report_run.update_result : $root.t.data_report_run.save_result
    },
  },
}
</script>
