import { RestService } from './RestService'

// TODO: Get generated types from backend
export type DataReportResult = {
  id: number
  dataReportDefinitionId: number
  runVariables: { [key: string]: unknown }
  result: { [key: string]: unknown }
  metaData: { [key: string]: unknown }
  createdAt: string
  updatedAt: string
}

export class DataReportResultService extends RestService {
  baseRoute: string

  constructor() {
    super()
    this.baseRoute = 'data-report-results'
  }

  async getById({ id }: { id: number }): Promise<DataReportResult> {
    return this.get(`${this.baseRoute}/${id}`)
  }

  async findMany({ dataReportDefinitionId }: { dataReportDefinitionId: number }): Promise<DataReportResult[]> {
    return this.get(`${this.baseRoute}`, {
      params: { dataReportDefinitionId },
    })
  }

  async findFirst({
    dataReportDefinitionId,
    runVariables,
  }: {
    dataReportDefinitionId: number
    runVariables: { [key: string]: string }
  }): Promise<DataReportResult> {
    return this.get(`${this.baseRoute}/first`, {
      params: { dataReportDefinitionId, runVariables: JSON.stringify(runVariables) },
    })
  }

  async create({ dataReportResultInput }: { dataReportResultInput: any }): Promise<DataReportResult> {
    return this.post(this.baseRoute, dataReportResultInput)
  }

  async update({ id, dataReportResultInput }: { id: number; dataReportResultInput: any }): Promise<DataReportResult> {
    return this.put(this.baseRoute, id, dataReportResultInput)
  }
}

const dataReportResultService = new DataReportResultService()
export default dataReportResultService
