<style scoped>
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tag input[type='checkbox'] {
  width: 16px;
  height: 16px;
  margin-top: 1px;
  margin-right: 8px;
}

.search-filters {
  display: flex;
  flex: 1;
  margin-left: 24px;
  min-width: 400px !important;
  max-width: 600px !important;
  padding-top: 8px;
}
.search-filters .autocomplete {
  min-width: 90% !important;
}

.icon {
  margin-right: 8px;
  fill: var(--primary);
}

.ac.primary .icon {
  fill: white;
}

.nowrap {
  white-space: nowrap !important;
  flex-wrap: nowrap !important;
}

h3 {
  margin-bottom: 8px;
}

.card {
  padding: 16px;
  border: 4px solid transparent;
  border-radius: 4px;
  background: white;
  box-shadow: 0 2px 4px 0 hsla(198, 45%, 10%, 0.12);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: space-between;
}

.tag {
  padding: 2px 4px;
  border-radius: var(--border-radius);
}

h3 .tag {
  margin: auto 8px auto 0;
}

h3 .count {
  margin: auto 0 auto 8px;
}

.link {
  color: var(--primary);
}

.details {
  width: 100%;
  text-decoration: underline;
}

.card.selected-run {
  background: var(--primary);
}

.card.selected-run h3,
.card.selected-run .row.report,
.card.selected-run .link,
.card.selected-run .ac.primary .icon {
  color: white;
  fill: white;
}
.card.selected-run .view-buttons .icon {
  color: white;
  fill: white;
}

.card .row {
  margin: 8px 0;
}

button.ac .column:not(:first-of-type) {
  margin-left: 4px;
  padding-top: 1px;
}

.lang.icon {
  margin: -4px 8px 0 0;
  min-width: 0;
}

.admin-alert {
  color: var(--negative);
  background-color: #ffe5e5;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 4px;
}

.preview {
  width: 100%;
  height: 100%;
  background-color: rgb(227, 227, 227);
}

.action-buttons {
  width: 100%;
  justify-content: space-around;
}

.card.selected-run .action-buttons >>> label {
  color: white;
}
</style>

<template>
  <transition @leave="leave">
    <loader v-if="isLoading" />
  </transition>
  <div class="header">
    <div class="row">
      <h1 style="margin-right: 8px">{{ t['my-actions'] }}</h1>
      <notification-badge :count="myRuns.length" />
    </div>
    <div class="search-filters">
      <autocomplete
        :data="filteredAutocompleteData"
        :options="{ placeholder: t.search }"
        :modelValue="activatedFilters"
        @update:modelValue="updateAutocompleteInput"
      />
    </div>
  </div>
  <subtitle style="font-style: italic; margin-top: -5px; margin-bottom: 16px">
    {{ t['client-edition-my-actions-subtitle'] }}
  </subtitle>
  <div class="row top" v-if="myRuns.length === 0" style="padding: 8px; flex-grow: 1000">
    <div class="column">{{ t.no_actions }}</div>
  </div>
  <template v-else>
    <div class="row" v-if="filteredRuns.length > 0">
      <div class="column nowrap" style="height: calc(100vh - 130px); overflow: auto">
        <div v-for="run in filteredRuns" :key="run.id" :class="['card', { 'selected-run': run.id === selectedRunId }]">
          <h3 class="nowrap">
            <div class="tag" :style="tagStyle(tagTypeCode(lastStep(run).type))">{{ t[lastStep(run).type] }}</div>
            <strong>{{ lastStep(run).name }}</strong>
            <span class="count">({{ lastStep(run).id }}/{{ run.workflow.actions.v().length }})</span>
            <div
              class="task"
              :class="[lastLog(run).status, { skipped: lastLog(run).skipped && lastLog(run).skipped !== 'rerun' }]"
            ></div>
          </h3>
          <div class="row report" v-if="run.context.fund_name">
            <div>
              <svg-icon class="lang icon" :name="'flag-' + run.context.language.lower().slice(0, 2)" />
            </div>
            <div class="nowrap">
              <span>{{ run.context.fund_name }}</span>
            </div>
          </div>
          <div class="row report nowrap">
            <div v-if="!run.context.fund_name">
              <svg-icon class="lang icon" :name="'flag-' + run.context.language.lower().slice(0, 2)" />
            </div>
            <div class="nowrap">
              <span>{{ periodDomain(run) }}</span>
              <span v-if="run.context.template">- {{ run.context.template.titleize() }}</span>
            </div>
          </div>

          <!-- Validation step: reject & accept buttons -->
          <div v-if="lastStep(run).type === 'user_validation'" class="row action-buttons">
            <div class="column">
              <button class="ac primary" @click.stop="validate_step(run.id, run.logs.v().last().id)">
                <div class="row nowrap">
                  <div class="column">
                    <svg-icon class="icon" name="pt-icon-tick" />
                  </div>
                  <div class="column">{{ t.accept }}</div>
                </div>
              </button>
            </div>
            <div class="column">
              <button class="ac secondary" @click.stop="rejectStep(run.id, run.logs.v().last().id)">
                <div class="row nowrap">
                  <div class="column">
                    <svg-icon class="icon" name="pt-icon-cross" />
                  </div>
                  <div class="column">{{ t.reject }}</div>
                </div>
              </button>
            </div>
          </div>

          <!-- Input step: instruction textarea with reject & submit buttons -->
          <user-input-actions
            v-if="lastStep(run).type === 'user_input'"
            class="action-buttons"
            :key="run.id"
            :run="run"
            :content-folder-id="lastStep(run).contentFolderId"
            @submit="
              event =>
                submitNarrativeContent({
                  run,
                  contentFolderId: lastStep(run).contentFolderId,
                  narrativeContentItem: event.narrativeContentItem,
                })
            "
            @reject="rejectStep(run.id, run.logs.v().last().id)"
          />

          <div class="row nowrap between view-buttons" style="margin-bottom: 0">
            <div class="column" style="margin-right: 16px">
              <div class="row">
                <div class="column">
                  <svg-icon class="icon" name="pt-icon-arrow-right" />
                </div>
                <div class="column">
                  <router-link class="link details" :to="run.link">{{ t.details }}</router-link>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="row">
                <div class="column">
                  <svg-icon class="icon" name="ic_remove_red_eye" />
                </div>
                <div class="column">
                  <a class="link" v-if="selectedRunId === run.id" @click.stop="hidePreview">{{ t.hide_preview }}</a>
                  <a class="link" v-else @click.stop="showPreview(run)">{{ t.show_preview }}</a>
                </div>
              </div>
            </div>
          </div>
          <div class="row expand" v-if="isAdmin() && !isAssignedToMe(run)">
            <div class="column expand admin-alert">
              <div>{{ t.view_action_admin1 }}</div>
              <div>{{ t.view_action_admin2 }} {{ stepEmail(run, lastStep(run)) }}.</div>
            </div>
          </div>
        </div>
      </div>
      <div class="column expand">
        <embed class="preview" v-if="selectedPdfSrc" type="text/html" :src="selectedPdfSrc" />
        <div v-else style="text-align: center">{{ t.no_preview }}</div>
      </div>
    </div>
    <div class="row top" v-else style="padding: 8px; flex-grow: 1000">
      <div class="column">{{ t.no_actions_filtered }}</div>
    </div>
  </template>
</template>

<script>
import { computed } from 'vue'
import { useProduction } from '../composables/useProduction'
import { useShares } from '../composables/useShares'
import { useProgress } from '../composables/useProgress'
import { getBuilderPreviewUrl } from '../utils/getBuilderPreviewUrl'
import contentItemService from '@100-m/hauru/src/services/ContentItemService'
import contentFolderService from '@100-m/hauru/src/services/ContentFolderService'

export const additions = { icon: 'pt-icon-check' }

export default {
  setup() {
    const { production } = useProduction()
    const { shares, fund_context, loaded: sLoaded } = useShares(false, false, $root.config.extra_share_characteristics)
    const { progress } = useProgress([sLoaded])

    // Initial loading
    const isLoading = computed(() => progress.value !== 1)
    return {
      production,
      isLoading,
      progress,
      shares,
      fund_context,
    }
  },
  data() {
    return {
      selectedRunId: null,
      selectedPdfSrc: null,
    }
  },
  methods: {
    isAdmin() {
      return ['admin', 'admin-reporting'].includes($root?.profile?.role)
    },
    showPreview(run) {
      this.selectedRunId = run.id
      this.selectedPdfSrc = this.previewUrl(run)
    },
    hidePreview() {
      this.selectedRunId = null
      this.selectedPdfSrc = null
    },
    previewUrl(run) {
      const url = getBuilderPreviewUrl({
        isDataReport: !!run.dataReportId,
        run: { ...run.context, id: run.id, fundId: run.context.fund_id, shareId: run.context.share_id },
      })

      return url
    },
    rejectStep(runId, logId) {
      set(['data', 'runs', runId, 'logs', logId, 'status'].join('.'), 'error')
    },
    async submitNarrativeContent({ run, contentFolderId, narrativeContentItem }) {
      if (!narrativeContentItem) {
        return this.validate_step(run.id, run.logs.v().last().id)
      }

      const ko = () => $root.toast({ description: $root.t.save_narrative_failure, type: 'fail', timeout: 2000 })
      const ok = () => {
        $root.toast({ description: $root.t.save_narrative_success, type: 'success', timeout: 2000 })

        this.validate_step(run.id, run.logs.v().last().id)
      }

      if (narrativeContentItem.id) {
        const response = await contentItemService.update({
          id: narrativeContentItem.id,
          contentItemInput: {
            value: narrativeContentItem.value,
            variables: narrativeContentItem.variables,
          },
        })

        return response?.id ? ok() : ko()
      } else {
        let _contentFolderId = contentFolderId

        if (!_contentFolderId) {
          const narrativeFolders = await contentFolderService.findManyByType({ type: 'narrative' })

          const defaultFolder = narrativeFolders.find(
            narrative => narrative.name.toLowerCase() === 'management comment',
          )

          _contentFolderId = defaultFolder?.id
        }

        const response = await contentItemService.create({
          contentItemInput: {
            contentFolderId: _contentFolderId,
            value: narrativeContentItem.value,
            variables: run.context,
          },
        })

        return response?.id ? ok() : ko()
      }
    },
    updateAutocompleteInput(event) {
      const events = event.map(v => v.split('.'))
      const groupedEvents = events.group('0')

      const selectedFilters = groupedEvents.map(g => g.map('1').join('|'))
      const newFilterSelected = this.$route.query.filter((v, k) => !this.$route.query.keys().includes(k))

      const query = { ...selectedFilters, ...newFilterSelected }
      this.$router.push({ query })
    },
  },
  computed: {
    filteredAutocompleteData() {
      const periodDomainValues = [...new Set(this.myRuns.map(run => this.periodDomain(run)))].sort()
      const runIds = [...new Set(this.myRuns.map(run => run.id))].sort()

      return {
        run_id: runIds.reduce((acc, id) => {
          acc[id] = id
          return acc
        }, {}),
        step_type: {
          user_input: 'user_input',
          user_validation: 'user_validation',
        },
        period: periodDomainValues.reduce((acc, period) => {
          acc[period] = period
          return acc
        }, {}),
      }
    },
    activatedFilters() {
      const queryParams = this.$route.query
      const activeFilters = queryParams.map(filterValue => filterValue.split('|'))

      return Object.entries(activeFilters)
        .filter(([filterName]) => filterName !== 'year')
        .flatMap(([filterName, filterValues]) => filterValues.map(value => `${filterName}.${value}`))
    },
    stepEmail() {
      return (run, step) => {
        let email = step.user
        if (!email) {
          // no email on the step: take the run owner:
          return run.context.owner
        }
        if (email.indexOf('@') > -1) {
          // the email is a true email (i.e. not an alias):
          return email
        }
        // the email is an alias, search for it in the userflows:
        email = (this.fund_context?.share_id && this.fund_context?.share_id[email]) || email
        if (Array.isArray(email)) email = email.join(', ')
        return email
      }
    },
    isAssignedToMe() {
      return run => {
        const theLastStep = this.lastStep(run)
        const email = this.stepEmail(run, theLastStep)
        return !email || [$root?.profile?.email, $root?.profile?.allowed_mailing_list || []].flat().includes(email)
      }
    },
    filteredProduction() {
      // NOTE: this computed has been copied from slash.js ==> need to make it common in the useProduction composable
      let production = this.production.v()
      if (!$root.query.scheduled)
        production = production
          .group(d =>
            ['template', 'isin', 'language', 'domain', 'workflow']
              .map(c => (typeof d[c] === 'object' ? ['wid', d[c].id].join('-') : d[c]))
              .join('-'),
          )
          .map(v => v.last())
          .v()
      if ($root.query.scheduled === 'assignee')
        production = production.filter(r => r.workflow.actions[r.logs.last().action_id].user === $root?.profile?.email)
      if ($root.query.scheduled === 'owner')
        production = production.filter(r => r.context.owner === $root?.profile?.email)
      if ($root.query.scheduled === 'year')
        production = production.filter(r => r.start_date >= new Date().minus('1 year').format())
      if ($root.query.scheduled === 'active')
        production = production.filter(
          r => !(r.workflow.actions.last().id === r.logs.last().action_id && r.logs.last().status === 'success'),
        )

      const filters = Object.entries($root.query)
        .filter(
          ([k, v]) =>
            !['search', 'selected', 'scheduled', 'year', 'action_id', 'step_type', 'period', 'run_id'].includes(k),
        )
        .map(([k, v]) => [k, v.split('|')])

      return production.filter(d =>
        filters.every(
          ([k, vs]) =>
            d[k] &&
            vs.some(
              v =>
                `${d[k]}`.replace('.', '') === v ||
                (/^>/.test(v) && d[k] > v.slice(1)) ||
                (/^</.test(v) && d[k] < v.slice(1)),
            ),
        ),
      )
    },
    /**
     * Get all runs for which the current user has something to do
     */
    myRuns() {
      return this.filteredProduction.filter(run => {
        const _lastStep = this.lastStep(run)
        const lastLogStatus = run.logs.v().last().status

        return (
          ['user_input', 'user_validation'].includes(_lastStep.type) &&
          lastLogStatus === 'running' &&
          !run.disabled &&
          (this.isAdmin() || this.isAssignedToMe(run))
        )
      })
    },
    /**
     * Apply the filters selected by the user at the top of the screen
     */
    filteredRuns() {
      const filters = Object.entries($root.query)
        .filter(([k]) => ['period', 'step_type', 'run_id'].includes(k))
        .map(([k, v]) => [k, v.split('|')])

      return this.myRuns.filter(_run =>
        filters.every(([filterKey, filterValues]) =>
          filterValues.some(value => {
            const runValue = {
              period: this.periodDomain(_run),
              step_type: this.lastStep(_run).type,
              run_id: _run.id.toString(),
            }[filterKey]

            return (
              (runValue && runValue.replace('.', '') === value) ||
              (runValue && runValue.includes(value)) ||
              (/^>/.test(value) && runValue > Number(value.slice(1))) ||
              (/^</.test(value) && runValue < Number(value.slice(1)))
            )
          }),
        ),
      )
    },
    periodDomain() {
      return run => `${run.context.period?.titleize() || ''} ${run.context.domain?.replace(/\|/g, ' | ')}`
    },
    tagTypeCode() {
      return tagType => ({ run_command: 4, wait_for: 1, user_input: 9, user_validation: 10 })[tagType]
    },
    tagStyle() {
      return code => `background: var(--cat${code});`
    },
    lastStep() {
      return run => (!run.logs.v().last().action_id ? {} : run.workflow.actions[run.logs.v().last().action_id])
    },
    lastLog() {
      return run => run.logs.v().last()
    },
  },
}
</script>
