import { ResolvedBuilderOptions } from '../composables/builderOptions'
import { format } from 'date-fns'
import * as locales from 'date-fns/locale'

function getCurrency(unit?: string, shareCurrency?: string) {
  if (!unit) return
  if (unit === 'shareCurrency') return shareCurrency
  if (['$', '€', '£'].includes(unit)) return unit.replace('$', 'USD').replace('€', 'EUR').replace('£', 'GBP')
  if (unit.length === 3) return unit
}
export function formatFactory({
  unit,
  digit,
  notation,
  lang = 'en',
  postFormat = null,
  shareCurrency,
}: Partial<ResolvedBuilderOptions>) {
  if (!unit && (digit === null || digit === undefined || digit === '')) return (num: number) => num?.toString() || num
  notation = notation || 'compact'
  const formatFn = (num: number) => {
    if ((!num && num !== 0) || typeof num !== 'number') return num ? '' + num : ''
    digit = Math.max(0, Math.min(digit || 0, 4))
    if (unit === 'bp') return Math.round(num * 10000) + 'bp'
    if (unit === '%') {
      return (
        Intl.NumberFormat(lang.slice(0, 2), {
          notation,
          minimumFractionDigits: digit,
          maximumFractionDigits: digit,
        }).format(num * 100) + '%'
      )
    }
    if (unit === 'base100') {
      return Intl.NumberFormat(lang.slice(0, 2), {
        notation,
        minimumFractionDigits: digit,
        maximumFractionDigits: digit,
      }).format(num * 100)
    }
    const _currency = getCurrency(unit, shareCurrency)
    if (_currency) {
      return Intl.NumberFormat(lang.slice(0, 2), {
        style: 'currency',
        currency: _currency,
        notation,
        minimumFractionDigits: digit,
        maximumFractionDigits: digit,
      }).format(num)
    }
    return Intl.NumberFormat(lang.slice(0, 2), {
      notation,
      minimumFractionDigits: digit,
      maximumFractionDigits: digit,
    }).format(num)
  }
  if (!postFormat) return formatFn
  return (num: number | string) => {
    // Dont post format / postformat strings
    if (typeof num === 'string') return num
    return postFormat({ formattedValue: formatFn(num), value: num, unit, digit })
  }
}

export function dateFormatFactory(
  dateFormat: 'custom' | Intl.DateTimeFormatOptions,
  customDateFormat?: string,
  lang: string = 'en',
) {
  const _lang = lang?.replace('_', '-') || 'en'
  if (!dateFormat) return (date: Date | string) => new Date(date).toLocaleDateString(_lang)
  if (dateFormat === 'custom') {
    return (date: Date | string) =>
      format(new Date(date), customDateFormat || 'yyyy-MM-dd', { locale: getLocale(lang) })
  }

  return (date: Date | string) => Intl.DateTimeFormat(_lang, dateFormat).format(new Date(date))
}

export function multiFormatFactory(formatOptions: ResolvedBuilderOptions) {
  if (formatOptions?.formatType === 'date') {
    return dateFormatFactory(formatOptions?.dateFormat, formatOptions?.customDateFormat, formatOptions?.lang)
  }
  return formatFactory({
    ...formatOptions,
    lang: formatOptions?.lang,
    shareCurrency: formatOptions?.shareCurrency,
  })
}

/**
 * langs that not mapped in locales
 */
const fallbackLocale = {
  zh: locales.zhCN,
  en: locales.enUS,

  // no need to handle, as fr is already in locales
  // 'fr': locales.fr,
}

const getLocale = (lang: string) => {
  let [langPrefix, langSuffix] = lang.split('_')
  langPrefix = langPrefix?.toLowerCase()
  const resolvedLang = `${langPrefix}${langSuffix?.toUpperCase() || ''}`
  // try to resolve the locale by the following order fullInput -> langPrefix -> fallbackLocale -> enUS
  return locales[resolvedLang] || locales[langPrefix] || fallbackLocale[langPrefix] || locales.enUS
}
