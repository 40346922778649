<template>
  <div class="narrative-content-form">
    <textarea
      v-model="localValue"
      class="narrative-textarea"
      :placeholder="$root.t[`${props.folderType}_items_page`].narrative_placeholder"
      @input="$emit('update:value', localValue)"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'

const props = defineProps<{
  folderType: string
  value: string | null
}>()

const localValue = ref(props.value || '')

watch(
  () => props.value,
  newValue => {
    localValue.value = newValue || ''
  },
)

defineEmits<{
  (e: 'update:value', value: string): void
}>()
</script>

<style scoped>
.narrative-content-form {
  width: 100%;
}

.narrative-textarea {
  min-width: 100%;
  min-height: 150px;
  padding: 12px;
  border: 1px solid var(--colors-gray1);
  border-radius: 4px;
  resize: vertical;
}
</style>
