import { RestService } from './RestService'

export class ExcelTemplateService extends RestService {
  constructor() {
    super()
    this.baseRoute = 'excel-templates'
  }

  async create(templateName, dataReportId, dataReportVariables, dqcReportId) {
    return await this.post(
      this.baseRoute,
      { templateName, dataReportId, dataReportVariables, dqcReportId },
      { responseType: 'blob' },
    )
  }

  async download(templateId) {
    return await this.get(`${this.baseRoute}/${templateId}/download`, { responseType: 'blob' })
  }

  async update(templateId, dataReportId, dataReportVariables, dqcReportId) {
    return await this.put(
      this.baseRoute,
      templateId,
      { templateId, dataReportId, dataReportVariables, dqcReportId },
      { responseType: 'blob' },
    )
  }

  async upload(templateId, dqcReportId, file) {
    // NOTE: file is expected as a base64 string*
    // This one does not respond with a file
    return await this.post(`${this.baseRoute}/${templateId}/version`, { dqcReportId, file })
  }

  async report(templateId, dataReportId, dataReportVariables, reportFilename) {
    return await this.post(
      `${this.baseRoute}/${templateId}/report`,
      { dataReportId, dataReportVariables, reportFilename },
      { responseType: 'blob' },
    )
  }

  async deleteOne(templateId) {
    return await this.delete(this.baseRoute, templateId)
  }

  async getRuns() {
    return await this.get(`${this.baseRoute}/runs`)
  }

  async deleteRun(runId) {
    return await this.delete(`${this.baseRoute}/runs/${runId}`)
  }
}

const excelTemplateService = new ExcelTemplateService()
export default excelTemplateService
