<template>
  <builder-block v-bind="props">
    <div class="toc-container flex-col">
      <div class="toc-header flex justify-between py-3">
        <div class="col-1"></div>
        <div class="col-2 flex w-24 items-center justify-center">
          {{ capitalizeFirstLetter(translate('page')) }}
        </div>
      </div>
      <div
        class="toc-content flex justify-between gap-2 py-3"
        v-for="{ title, pageNumber } in computeTableOfContents"
        :key="title + pageNumber"
      >
        <div class="toc-page-title col-1 flex flex-wrap items-center justify-center">
          {{ translate(title) }}
        </div>
        <div class="toc-page-number col-2 flex w-24 items-center justify-center">
          {{ pageNumber || '' }}
        </div>
      </div>
    </div>
  </builder-block>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue'
import useTranslations from '../composables/translations'
import { getTableOfContentByTargetComponentName } from '../lib/getTableOfContent'

import { capitalizeFirstLetter } from '../../client-edition/utils/reportFileHandlers'
import { tableOfContent } from './stories'

const props = defineProps(['data', 'options', 'context'])
const { translate } = useTranslations(props as any)

const computeTableOfContents = computed(() => {
  return getTableOfContentByTargetComponentName({ state: props.context.layout?.nodes || [] })
})
</script>
<script lang="ts">
export default {
  styles: {
    'toc-container': 'font-size: 18px;',
    'toc-header': '',
    'toc-content': '',
    'toc-page-title': '',
    'toc-page-number': '',
  },
  story: tableOfContent,
}
</script>
