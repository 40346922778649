export function useVersion() {
  const fetchVersion = async () => {
    const resolvedInfo = { version: '', commitId: '', commitMessage: '' }

    try {
      const response = await fetch(`/version.txt`)
      const responseText = await response.text()
      const [version = '', commitInfo = ''] = responseText.split('\n')

      if (!version || version.startsWith('<')) {
        // HTML page, probably a redirection
        return resolvedInfo
      }

      if (commitInfo) {
        resolvedInfo.version = version
        resolvedInfo.commitId = commitInfo.split(' ')[0] || commitInfo
        resolvedInfo.commitMessage = commitInfo.split(' ').slice(1).join(' ')
        return resolvedInfo
      }

      resolvedInfo.version = version
      return resolvedInfo
    } catch (e) {
      console.log('Error fetchVersion failed:', e)
      resolvedInfo.version = ''
      resolvedInfo.commitId = ''
      resolvedInfo.commitMessage = ''
      return resolvedInfo
    }
  }

  return { fetchVersion }
}
