<template>
  <div class="image-content-form">
    <input ref="fileInput" type="file" accept="image/*" style="display: none" @change="$emit('fileSelected', $event)" />
    <div class="row" style="gap: 16px; align-items: center">
      <button class="button primary" @click="$emit('triggerFileInput')">
        {{ $root.t[`${props.folderType}_items_page`].import }}
      </button>
      <span class="filename">
        {{ fileName || $root.t[`${props.folderType}_items_page`].no_uploaded_file }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  folderType: string
  fileName?: string
}>()

defineEmits<{
  (e: 'triggerFileInput'): void
  (e: 'fileSelected', event: Event): void
}>()
</script>

<style scoped>
.image-content-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.filename {
  flex: 1;
}
</style>
