import { RestService } from './RestService'

export type ContentItem = {
  id: number
  value: string
  contentFolderId: number
  variables: Record<string, string>
}

export class ContentItemService extends RestService {
  baseRoute: string

  constructor() {
    super()
    this.baseRoute = 'content-items'
  }

  async findOne({
    contentFolderId,
    variables,
  }: {
    contentFolderId: number
    variables: { [key: string]: string }
  }): Promise<ContentItem> {
    return this.get(`${this.baseRoute}/find-one`, {
      params: {
        contentFolderId,
        variables: JSON.stringify(variables),
      },
    })
  }

  async create({ contentItemInput }: { contentItemInput: any }): Promise<ContentItem> {
    return this.post(this.baseRoute, contentItemInput)
  }

  async update({ id, contentItemInput }: { id: number; contentItemInput: any }): Promise<ContentItem> {
    return this.put(this.baseRoute, id, contentItemInput)
  }

  async deleteOne({ id }: { id: number }): Promise<ContentItem> {
    return this.delete(this.baseRoute, id)
  }
}

const contentItemService = new ContentItemService()
export default contentItemService
