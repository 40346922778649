<template>
  <builder-block :data="props.data" :options="props.options" :context="props.context">
    <cjs-bubble-chart
      class="w-full"
      :data="formattedData"
      :options="final_props.options"
      v-if="formattedData?.keys()"
    ></cjs-bubble-chart>
  </builder-block>
</template>

<script setup>
import { computed, defineProps, toRaw } from 'vue'
import { useBuilderComponentInjectableOptions } from '../composables/builderComponent'
import { getXValues } from '../composables/builderOptions'
import { bubble } from './stories'
import useTranslations from '../composables/translations'
import { generatePaletteLinear } from '../lib/linearGradientColorCalculate'

const definedProps = defineProps(['data', 'options', 'context'])
const props = useBuilderComponentInjectableOptions(definedProps, {})
const { translate } = useTranslations(definedProps)
const DEFAULT_BUBBLE_PALETTE = ['#46CDC9', '#084081']
const axisMembers = axis => {
  return props.data?.map(d => d[props.options[axis]]).filter((d, i, arr) => arr.indexOf(d) === i) || []
}
const axisFormatter = axis => {
  const sample = axisMembers(axis)[0]
  if (typeof sample === 'number') {
    const formatArg = '.' + (props.options.digit ?? 1) + (props.options.unit ?? '')
    return value => format(`${formatArg}`)(value)
  }
  return value => value
}

const primaryBG = computed(() => {
  const styleSheet = Object.entries(definedProps.context.layout?.theme?.loadedStylesheets || [[]])[0][1]
  return props.options.palette[0] || styleSheet?.primary || DEFAULT_BUBBLE_PALETTE[1]
})
const secondaryBG = computed(() => {
  const styleSheet = Object.entries(definedProps.context.layout?.theme?.loadedStylesheets || [[]])[0][1]
  return props.options.palette[1] || styleSheet?.secondary || DEFAULT_BUBBLE_PALETTE[0]
})
const xMembers = computed(() => {
  return axisMembers('x')
})
const yMembers = computed(() => {
  return axisMembers('y')
})
const zMembers = computed(() => {
  return axisMembers('z')
})
const xFormatter = computed(() => {
  return axisFormatter('x')
})
const yFormatter = computed(() => {
  return axisFormatter('y')
})
const xLabels = computed(() => {
  return typeof axisMembers('x')[0] === 'string' ? axisMembers('x') : []
})
const yLabels = computed(() => {
  return typeof axisMembers('y')[0] === 'string' ? axisMembers('y') : []
})
const xAxisTitle = computed(() => {
  return translate.value(props.options.xTitle) || ''
})
const yAxisTitle = computed(() => {
  return translate.value(props.options.yTitle) || ''
})
const datasetGroups = computed(() => {
  return axisMembers('groupBy') || ['aggregate']
})
const datasets = computed(() => {
  if (!datasetGroups.value) return
  return datasetGroups.value.map((d, i) => {
    return { label: d, key: d, backgroundColor: bubblePalette.value[i] }
  })
})
const bubblePalette = computed(() => {
  if (!datasetGroups.value) return
  const length = datasetGroups.value.length === 1 ? 2 : datasetGroups.value.length
  return generatePaletteLinear(primaryBG.value, secondaryBG.value, length)
})
const formattedData = computed(() => {
  if (!(datasetGroups.value && xMembers.value && yMembers.value && zMembers.value)) return []
  const zAvg = zMembers.value.reduce((sum, num) => sum + num, 0) / zMembers.value.length
  return datasetGroups.value.reduce((acc, _, i) => {
    acc[datasetGroups.value[i]] = props.data
      .filter(d => d[props.options.groupBy] === datasetGroups.value[i])
      .map(d => {
        return {
          x: typeof d[props.options.x] === 'string' ? xMembers.value.indexOf(d[props.options.x]) : d[props.options.x],
          y: typeof d[props.options.y] === 'string' ? yMembers.value.indexOf(d[props.options.y]) : d[props.options.y],
          r: props.options.bubbleSize
            ? props.options.bubbleSize
            : props.options.z === 'bubble_size'
              ? d[props.options.z]
              : (d[props.options.z] / zAvg) * 15,
        }
      })
    return acc
  }, {})
})

const _options = {
  formatX: xFormatter,
  formatY: yFormatter,
  xLabels,
  yLabels,
  xAxisTitle,
  yAxisTitle,
  datasets: toRaw(datasets),
  mergeableOptions: {
    animations: false,
    hideExternalTooltip: true,
    events: [],
  },
}

const final_props = useBuilderComponentInjectableOptions(props, _options)
</script>

<script>
export default {
  api: {
    x: {
      label: 'X Axis',
      select: ({ data_component }) => {
        return data_component && Object.entries(data_component[0] || {}).map(([k, v]) => k)
      },
      default: data => getXValues(data)[0],
      attrs: {
        required: true,
      },
    },
    y: {
      label: 'Y Axis',
      select: ({ data_component }) => {
        return data_component && Object.entries(data_component[0] || {}).map(([k, v]) => k)
      },
      default: data => getXValues(data)[1],
      attrs: {
        required: true,
      },
    },
    z: {
      label: 'Z Axis (bubble plot)',
      select: ({ data_component }) => {
        return (
          data_component &&
          Object.entries(data_component[0] || {})
            .filter(([k, v]) => typeof v === 'number')
            .map(([k, v]) => k)
        )
      },
      default: () => 'weight',
      attrs: {
        required: true,
      },
    },
    groupBy: {
      label: 'Group By',
      select: ({ data_component }) => {
        return (
          data_component &&
          Object.entries(data_component[0] || {})
            .filter(([k, v]) => typeof v === 'string')
            .map(([k, v]) => k)
        )
      },
      default: data => getXValues(data)[0],
      attrs: {
        required: true,
      },
    },
    unit: {
      label: 'Unit',
      default: () => '%',
    },
    digit: {
      label: 'Decimals',
      default: () => 1,
      attrs: {
        type: 'number',
      },
    },
    xTitle: {
      label: 'X Axis Title',
      dataList: ({ context }) => {
        return Object.entries(context.translations).map(([key, value]) => ({ value: key, name: value }))
      },
    },
    yTitle: {
      label: 'Y Axis Title',
      dataList: ({ context }) => {
        return Object.entries(context.translations).map(([key, value]) => ({ value: key, name: value }))
      },
    },
    bubbleSize: {
      label: 'Bubble Size',
      default: () => 20,
      attrs: {
        type: 'number',
      },
    },
  },
  styles: {
    'plot-dark-color': {
      name: 'Bubble Dark Color',
      css: `background-color: #084081;`,
    },
    'plot-light-color': {
      name: 'Bubble Light Color',
      css: `background-color: #46CDC9;`,
    },
  },
  story: bubble,
}
</script>
