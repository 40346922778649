<style scoped>
.validation-error {
  color: var(--colors-negative);
  font-size: small;
}
:deep(.content-item-variable-input select),
:deep(.content-item-variable-input input) {
  @apply flex w-full truncate rounded border-transparent bg-slate-50 p-0 pl-1 pr-6 [font-size:inherit];
}
:deep(.missing-dependencies) {
  @apply h-7 text-xs;
  @apply pt-2;
}
</style>
<template>
  <fieldset class="content-item-variable-input">
    <slot name="label" :variableDefinition="variableDefinition">
      <label>{{ variableDefinition.name }}</label>
    </slot>
    <input v-if="['input', 'readonly'].includes(inputType) || !inputType" :value="value" @input="updateValue" />
    <template v-else-if="missingDependencies?.length">
      <slot name="missing-dependencies" :missingDependencies="missingDependencies">
        <div class="missing-dependencies">Please select a value for: {{ missingDependencies.join(', ') }}</div>
      </slot>
    </template>
    <div v-else-if="!inputData">Loading</div>
    <select v-else-if="inputType === 'select' && inputData" :value="value" @input="updateValue">
      <option></option>
      <option v-for="opt in inputData" :value="opt.value" :key="opt.name">{{ opt.name }}</option>
    </select>
  </fieldset>

  <span v-if="props.validationError" class="validation-error">
    {{ t['data_report_variables'][props.validationError] }}
  </span>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { VariableDefinition, VariableOption, variableTypes } from '@100-m/hauru/src/components/form/drVariables/lib'

interface IProps {
  variableDefinition: VariableDefinition
  value?: string | number
  inputData?: VariableOption[]
  missingDependencies?: string[]
  validationError?: string
}

const props = defineProps<IProps>()

const emit = defineEmits(['update:value'])
function updateValue(event: Event) {
  emit('update:value', (event.target as HTMLSelectElement).value)
}
const inputType = computed(() => {
  return variableTypes[props.variableDefinition?.variableType]?.inputType
})
</script>
