<template>
  <component class="m-auto h-full" :is="resolveVersion" :data="props.data" :options="props.options">
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData"></slot>
    </template>
  </component>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue'

const props = defineProps<{
  data: any
  options: {
    version: string
  }
}>()

const resolveVersion = computed(() => {
  const version = props.options.version
  return `nx-bar-${version}`
})
</script>
