<style scoped>
.mr2 {
  margin-right: 8px;
}
.info {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: auto;
}
.block.info {
  max-height: 220px;
  overflow: auto;
}
.info .row {
  padding: 8px;
  font: var(--p2);
}
.info .row > * {
  display: flex;
  align-items: center;
}
.info .key {
  font-weight: 600;
  margin-right: 4px;
  min-width: 110px;
}
.info .value .left {
  color: var(--inactive);
  margin-left: 4px;
}
.wip .board .line .cell.name {
  align-items: flex-start;
}
.wip .board .line .cell.status {
  flex: 0 !important;
}
.wip .board .line .cell.due {
  flex-direction: row;
}
.box-overlay {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: var(--form-height);
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(0, 0, 0, 0.75);
  min-width: 100% !important;
  margin: 0 !important;
}
.box-overlay::after {
  content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="none" shape-rendering="geometricPrecision"><path d="M18 6L6 18"/><path d="M6 6l12 12"/></svg>');
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  padding: 5px;
  background: #666;
}
.box-overlay .inside {
  height: calc(100% - 80px);
  min-width: 100%;
  margin: 40px !important;
  background: white;
}
.box-overlay .inside iframe {
  height: 100%;
  width: 100%;
}
h2 .prev {
  cursor: pointer;
  width: 30px;
  height: 30px;
  transform: rotate(180deg);
}
h2 .tag {
  margin: 0 8px 0 auto;
}
h2 .name {
  margin-left: auto;
  max-width: calc(100% - 160px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.2;
}
h2 .count {
  margin: 4px;
  font: var(--h3);
}
h2 .task {
  margin-right: auto;
}
h2 .next {
  cursor: pointer;
  width: 30px;
  height: 30px;
}
h2 :is(.prev, .next).inactive {
  pointer-events: none;
  fill: var(--inactive);
}
.description {
  width: 100px;
  margin: 4px;
}
.row.error {
  color: var(--negative);
}

.reject {
  border: 1px solid var(--primary);
  margin-left: 8px;
  color: var(--primary);
  background-color: #fff;
}
.reject:hover {
  background: var(--colors-primary-dark);
}

.rerun {
  background: var(--cat1);
  color: white;
}

.skip {
  background: var(--cat10);
  color: white;
}

.link {
  cursor: pointer;
  color: var(--primary);
}
.link.inactive {
  color: var(--inactive);
  pointer-events: none;
}
.link:hover,
.link.active {
  font-weight: bold;
}
.action-buttons {
  display: flex;
  gap: 8px;
}
/* HACK */
.box-form {
  overflow: auto;
}
.wfblock {
  min-height: 0;
  overflow: auto;
}
h1 {
  gap: 12px;
  justify-content: space-between;
}
h1 input {
  font: var(--h2);
}
input.duration {
  width: 70px;
}
select.interval {
  width: 90px;
  margin-top: 4px;
}
input,
textarea,
select {
  --input: var(--colors-gray02);
  border: 1px solid var(--colors-gray1);
}
</style>

<template lang="pug">
transition(@leave='leave')
  loader(v-if="isLoading")
h1(v-if="run")
  div(v-if="$root.query.rid") Run {{ run.id }} - {{ (run.context.isin || run.context.fundId) + '-' + run.context.language + '-' + run.context.domain.slice(0, 7) }}
  input(v-if="!$root.query.rid" style="margin: 0;width: 1200px;background: white;" v-model="workflow.name" placeholder="Workflow name")

  .action-buttons(v-if="!$root.query.rid")
    button.secondary(@click="cancel")
      div {{ t.cancel }}
    button.primary(@click="setShouldConfirmClear(true)")
      div {{ t.clear }}
    button.primary(@click="saveWorkflow" :disabled="!workflow.name")
      div {{ t.save_workflow }}
subtitle(style="font-style: italic; margin-top: -5px;") {{ t['client-edition-workflow-subtitle'] }}
.row(v-if="run && rid")
  .block.info
    .row(v-for="k in detail_info.map(v => localStorage.PROJECT === 'greenrock' && v === 'name' ? 'workflow' : v)")
      .key {{ t[k] || k.titleize() }}:
      .value.owner(style="margin: -3px 0;min-width: 0;" :style="{ background: 'var(--cat' + ((run.context[k] || '').charCodeAt(0) % 10 + 1) + ')' }" :tt="(run.context[k].split('@')[0] || '').titleize()" v-if="k === 'owner' && run.context[k]") {{ run.context[k].split('@')[0].split('.').map('0').join('').upper() }}
      .value.document(v-else-if="k === 'document'" :tt="[run.document].filter().join(' / ')") {{ run.short_code }}
      .value.jurisdiction(v-else-if="k === 'jurisdiction'") {{ (run.countries || []).join(',') }}
      svg-icon.lang.icon.value(style="margin: -4px 0;min-width: 0;" :name="'flag-' + (run.context.language.toLowerCase().slice(0, 2))" v-else-if="k === 'language'")
      ui-asset.file_icon(v-else-if="k === 'format'" :name="'icon_' + run.context.format")
      .value(v-else-if="k === 'domain'")
        div {{ run.context.domain.includes('Q') ? run.context.domain : new Date(run.context.domain).format('DD/MM/YY') }}
      .value(v-else-if="k === 'id'") {{ run.id }}
      .value(v-else-if="k === 'stamp'") {{ run.stamp }}
      .value(v-else-if="k === 'name'") {{ run.report.name.titleize() }}
      .value(v-else-if="k === 'workflow'") {{ run.workflow?.name.titleize() }}
      .value(v-else-if="k === 'start_date'") {{ new Date(run.context['run_time']).format('DD/MM/YYYY') }}
      .value(v-else-if="['template', 'fund_name'].includes(k)") {{ (run.context[k] || '').titleize() }}
      .value(v-else) {{ run.context[k] || '-' }}
    board(:data="boardData" :metadata="{ columns: ['name'] }")
      template(v-slot:header-name="s")
        div Work in progress & Report assets
      template(v-slot:header-status="s")
        div
      template(v-slot:cell-name="s")
        .row
          .link(v-if="s.line.url !== 'preview' && run.context.format !== 'xlsx'"
              :class="{ inactive: (!['final'].includes(s.line.url) && !get(s.line.url.replace('[fund]', 'userflows.' + run.context.fund_id).replace('[date]', run.context.domain.slice(0, 7)))) || (s.line.url === 'final' && !(impressions || []).v().find(i => i.run_id === +$root.query.rid)) }"
              @click="update_query({ tab: s.line.url.replace('[fund]', 'userflows.' + run.context.fund_id).replace('[date]', run.context.domain.slice(0, 7)) })") {{ s.line.name }}
          a.link(v-if="s.line.url !== 'preview' && run.context.format === 'xlsx'" download=""
              :href="getDocumentUrl((impressions || []).v().find(i => i.run_id === run.id)?.alias)") {{ s.line.name }}
          // Preview move into new browser tab
          .link(v-if="s.line.url === 'preview'")
            a(v-if="run.context.format === 'xlsx'" @click="downloadExcelTemplate") {{ s.line.name }}
            a(v-else target="_blank" :href="download_url") {{ s.line.name }}
      template(v-slot:cell-status="s")
        .task(:class="get(s.line.url.replace('[fund]', 'userflows.' + run.context.fund_id).replace('[date]', run.context.domain.slice(0, 7))) ? 'success' : 'error'" v-if="s.line.url")

      template(v-slot:cell-due="s")
        .row(v-if="s.line.due")
          div {{ s.line.due }}
          span.delay(:class="s.line.received ? (new Date(s.line.due) - new Date(s.line.received || new Date()) <= 0 ? 'plus' : 'minus') : 'inactive'") {{ (new Date(s.line.due) - new Date(s.line.received || new Date())) <= 0 ? '+' : '-' }}{{ Math.round(Math.abs(new Date(s.line.due) - new Date(s.line.received || new Date())) / 86400000) }}
.block.expand.wfblock(v-if="run")
  workflow-calendar(v-if="enrichedWorkflow" :data="enrichedWorkflow" @update:data="workflow = $event" :workflow="workflow" :run="run")
.box-overlay.row(@click="update_query({ tab: null })" v-if="run && $root.query.tab === 'document'")
  .column.bottom
    a(download="" target="_blank" :href="getDocumentUrl(document.alias)" v-for="document in documents.filter(d => d.run_id == run.id)") {{ document.alias.split('/').last() }}
  .inside(v-if="documents.filter(d => d.run_id == run.id && d.alias.endsWith('.pdf')).v().last()")
    iframe(:src="getDocumentUrl(documents.filter(d => d.run_id == run.id && d.alias.endsWith('.pdf')).v().last().alias)")
//.box-overlay.data-audit.row(@click="update_query({ tab: null })" v-show="/data-audit/.test($root.query.tab)" v-if="$root.query.rid")
  .inside
    iframe(src="/advanced/data-quality")
    //iframe(:src="'/advanced/data-audit?userflow=' + run.context.fund_id")
//.box-overlay.data-quality.row(@click="update_query({ tab: null })" v-show="/data-quality/.test($root.query.tab)" v-if="$root.query.rid")
  .inside
    iframe(src="/advanced/data-quality")
.box-overlay.final.row(@click="update_query({ tab: null })" v-show="$root.query.tab === 'final'" v-if="rid && run && run.context.format !== 'xlsx' && (impressions || []).v().find(i => i.run_id === +rid)")
  .inside
    iframe(:src="getDocumentUrl((impressions || []).v().find(i => i.run_id === +rid).alias)")
.box-overlay.comment.row(@click="update_query({ tab: null })" v-if="$root.query.tab === 'comment'")
  .inside(@click.stop="" style="display:flex;flex-direction: column;max-width: 600px;padding: 20px;")
    .timeline(v-if="run")
      .event(v-for="event in logs.v().concat(run.context.comments || []).sort('start')")
        template(v-if="event.id")
          .task(:class="event.status")
          .text {{ workflow.actions[event.action_id].name }}
          .start {{ new Date(event.start).format('YYYY-DD-MM hh:mm') }}
        template(v-else)
          .owner(:style="{ background: 'var(--cat' + ((event.user || '').charCodeAt(0) % 10 + 1) + ')' }" :tt="(event.user.split('@')[0] || '').titleize()") {{ event.user.split('@')[0].split('.').map('0').join('').upper() }}
          .text {{ event.text }}
          .start {{ new Date(event.start).format('YYYY-DD-MM hh:mm') }}
    form.coluln(style="margin-top: auto;border-top: 1px solid black;height: 100px;" @submit.prevent="set(['data', 'runs', run.id, 'context', 'comments'].join('.'), (run.context.comments || []).concat({ text: $event.target.children[1].children[0].value, user: $root?.profile?.email, start: new Date().toISOString() }));$event.target.children[0].value = ''")
      .row
        div {{ t.workflow_comment }}
      .row
        textarea.mr2.expand
        button.primary Submit
//.box-overlay.assets.row(@click="update_query({ tab: null })" v-if="$root.query.tab && !/(data-quality|data-audit)/.test($root.query.tab) && !['preview', 'comment', 'document', 'final'].includes($root.query.tab)")
  .inside
    iframe(:src="get($root.query.tab)" v-if="/^(\\/|http)/.test(get($root.query.tab))")
    div(style="padding: 40px;" v-html="get($root.query.tab)" v-else)
.box-form.block(v-if="run && workflow && action.id")
  .column.expand.between(v-if="!$root.query.rid")
    .form(style="min-width: 100%;")
      .row
        label(style="margin-left: 30px") {{ t.action_name }}
        h2
          svg-icon.prev(:class="{ inactive: +$root.query.action_id === +workflow.actions.v().first().id }" @click.native="update_query({ action_id: +$root.query.action_id - 1 })" name="ic_navigate_next")
          input.name(style="margin: 0;min-width: 400px;" v-model="action.name")
          .count ({{ action.id }}/{{ workflow.actions.v().length }})
          svg-icon.next(:class="{ inactive: +$root.query.action_id === +workflow.actions.v().last().id }" @click.native="update_query({ action_id: +$root.query.action_id + 1 })" name="ic_navigate_next")

      .row
        .column.expand
          label {{ t.instruction }}
          input(type="text" style="min-width: 100%;" v-model="action.instruction")
      .row
        // ACTION TYPE
        .column
          label {{ t.step_type }}
          select(v-model="action.type")
            option
            option(:value="cmd_type" :id="t.step_type" v-for="cmd_type in ['run_command', 'wait_for', 'user_input', 'user_validation']") {{ t[cmd_type] || cmd_type.titleize() }}

        // COMMAND LIST
        .column(v-show="action.type === 'run_command'")
          label {{ t.command }}
          select(v-model="action.command")
            option
            option(:value="command" :id="t.command"  v-for="command in (commands || [])") {{ command }}

        // COMMAND OPTION
        .column.expand(v-if="action.type === 'run_command' && action.command")
          label {{ t.options }}
          textarea.options(v-model="action.rawOptions" @input="action.options = jsonParsedString(action.rawOptions)" :style="{ border: !action.rawOptions || jsonParsedString(action.rawOptions) ? '' : '1px solid red' }")
        
        // disable input_steps
        .column.expand(v-if="action.type === 'wait_for'")
          label {{ 'wait_for' ? 'Watched Location' : 'Content Location' }}
          input(type="text" v-model="action.url" style="display: flex; min-width: 100%;")

        .column(v-if="action.type === 'user_input'")
          label {{ t.content_folder_name }}
          select(v-model="action.contentFolderId" style="display: flex; min-width: 200px;")
            option(v-for="contentFolder in narrativeContentFolders" :value="contentFolder.id") {{ contentFolder?.name }}
        
        // Assigned
        .column(style="display: flex;min-width: 300px;" v-if="['user_input', 'user_validation'].includes(action.type)")
          div(style="display: flex; min-width: 100%; padding-bottom:3px")
            label {{ t.assigned_user }}
            button.primary(style="margin-left: 4px; padding:4px; width:24px; height:24px; display:flex;")
              svg-icon(name="nx-plus" @click="isOpenPopupAddUser = true" class="icon" style="fill: white; width: 15px; height: 15px;")
          select(v-model="action.user" style="display: flex;min-width: 60%;")
            option
            option(v-for="user in users") {{ user.email }}

        .column.expand(style="padding: 0px;")
          .row.expand
            // On sucess
            .column(style="display: flex;min-width: 130px;" v-if="action.type === 'user_validation'")
              .label {{ t.on_success_type || 'on_success_type' }}
              select(v-model="action.onsuccess.type" @change="() => { if (!action.onsuccess.type) action.onsuccess.args = null }")
                option
                option trigger
                option unlock

            // On sucess target
            .column.expand(style="display: flex;flex: 1;" v-if="action.type === 'user_validation'")
              .label {{ t.on_success_target || 'on_success_target' }}
              input(type="text" style="display: flex;min-width: 100%;" v-model="action.onsuccess.args" :disabled="!action.onsuccess.type")
          .row.expand(v-if="action.onsuccess.type === 'trigger'")
            div(style="margin-left: 12px;color: grey; font-size: 12px") {{ 'ℹ️ ' + (t.help_trigger || 'help_trigger') }}
          .row.expand(v-if="action.onsuccess.type === 'unlock'")
            div(style="margin-left: 12px;color: grey; font-size: 12px") {{ 'ℹ️ ' + (t.help_unlock || '') }}

  .column.expand.between(v-else)
    h2
      svg-icon.prev(:class="{ inactive: +$root.query.action_id === +workflow.actions.v().first().id }" @click.native="update_query({ action_id: +$root.query.action_id - 1 })" name="ic_navigate_next")
      .tag(:style="'background: var(--cat' + { run_command: 4, wait_for: 1, user_input: 9, user_validation: 10 }[action.type] + ');'") {{ t[action.type] }}
      .name {{ action.name }}
      .count ({{ action.id }}/{{ workflow.actions.v().length }})
      .task(:class="[log.status, { skipped: log.skipped && log.skipped !== 'rerun' }]")
      svg-icon.next(:class="{ inactive: +$root.query.action_id === +workflow.actions.v().last().id }" @click.native="update_query({ action_id: +$root.query.action_id + 1 })" name="ic_navigate_next")
      div(:tt="(run.context.comments || []).length + ' ' + t.comments" style="display: flex;font: var(--h3);" @click="update_query({ tab: 'comment' })") {{ (run.context.comments || []).length || '' }}
        svg-icon.comment(style="width: 16px;margin: 0 4px 0 4px;" name="pt-icon-comment")
    .column.expand
      .row(style="align-items: center;")
        .description Instruction:
        div(style="margin: 4px;font-style: italic;font-weight: 600;" v-html="action.instruction && action.instruction.replace('[fund]', 'userflows.' + run.context.fund_id).replace('[date]', run.context.domain.slice(0, 7))")
      .row.error(v-if="log.status === 'error' && log.error")
        .description Errors:
        div {{ log.error }}        
      .row.expand
        //- Todo: Use user-input-actions component
        loading-icon(v-if="isFetchingNarrative" :size="32" style="margin: auto; padding: 12px 0")
        textarea(v-if="!isFetchingNarrative && ['user_input'].includes(action.type) && log.status === 'running' && narrativeContentItem" v-model="narrativeContentItem.value" style="min-width:100%;margin:8px 0;border: var(--border);")
        
      .row.expand 
        button.primary.mr2(@click="submitNarrativeContent(() => { validate_step(run.id, log.id) })" v-if="['user_input', 'user_validation'].includes(action.type) && log.status === 'running'") {{ action.type === 'user_input' ? t.submit : t.accept }}
        button.reject.mr2(@click="set(['data', 'runs', run.id, 'logs', log.id, 'status'].join('.'), 'error')" v-if="['user_input', 'user_validation'].includes(action.type) && log.status === 'running'") {{ t.reject }}
        button.rerun.mr2(@click="rerun_step(run, action.id)" v-if="log.id && log.status !== 'running'") {{ t.rerun }}
        button.skip.mr2(@click="skip_step(run.id, log.id)" v-if="log.id && (log.status === 'running' || action.type === 'run_command') && !['user_input', 'user_validation'].includes(action.type)") {{ t.skip }}
      .row.expand(v-if="log.trace")
        pre(style="height: 150px;width: 100%;padding: 8px;margin-top: 8px;border: var(--border);overflow: auto;") {{ log.trace }}

  popup(:show="shouldConfirmClear")
    template(v-slot:header)
      | {{t["confirmation_remove"]}}
    template(v-slot:content)
      | {{t["confirm_reset_form"]}}
    template(v-slot:action)
      button.secondary-action(@click="setShouldConfirmClear(false)") {{t['confirmation_btn_close']}}
      button.main-action(@click="resetForm") {{t["confirmation_btn_clear"]}}
  popup(:show="isOpenPopupAddUser")
    template(v-slot:header)
      | {{t["add_user"]}}
    template(v-slot:content)
      input(type="text" v-model="userToAdd" style="width: 100%;")
    template(v-slot:action)
      button.secondary-action(@click="cancelAddUser") {{t['confirmation_btn_close']}}
      button.main-action(@click="addUser") {{t["confirmation_btn_add_user"]}}
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue'
import Board from '../../asset-management/components/board.vue'
import config from '../../../config'
import { useRun } from '../composables/useRun'
import { useWorkflows } from '../composables/useWorkflows'
import { useWorkflow } from '../composables/useWorkflow'
import { useCommands } from '../composables/useCommands'
import { useImpressions } from '../composables/useImpressions'
import { useUsers } from '../composables/useUsers'
import excelTemplateService from '@100-m/hauru/src/services/ExcelTemplateService'
import contentFolderService from '@100-m/hauru/src/services/ContentFolderService'
import { downloadResponseAsFile } from '@100-m/hauru/src/services/RestService'
import { generateUniqueName } from '../utils/generateUniqueName'
import { getAssetPublicUrl } from '@100-m/hauru/src/applications/builder/lib/assetManagement'
import { getBuilderPreviewUrl } from '../utils/getBuilderPreviewUrl'
import contentItemService from '@100-m/hauru/src/services/ContentItemService'

export default {
  setup() {
    const { run, rid, logs, periodComments, loaded: rLoaded } = useRun()
    const { workflows } = useWorkflows()
    const { workflow, resetWorkflow, action, actionId, id, loaded: wLoaded } = useWorkflow()
    const { commands } = useCommands()
    const { impressions } = useImpressions()
    const { users } = useUsers()

    const shouldConfirmClear = ref(false)
    const isOpenPopupAddUser = ref(false)
    const userToAdd = ref('')
    const narrativeContentFolders = ref([])
    const defaultNarrativeContentFolder = ref(null)
    const narrativeContentItem = ref(null)
    const isFetchingNarrative = ref(false)

    const isLoading = computed(() => !rLoaded || !wLoaded)
    const enrichedWorkflow = computed(() => {
      if (run.value && workflow.value && run.value.keys().length) {
        const actions = workflow.value.actions.v().map(a => {
          const log =
            Object.values(logs.value)
              .filter(l => l.action_id === a.id)
              .reverse()[0] || {}
          const { id: lid, ...logData } = log
          return { lid, ...logData, ...a }
        })

        return { ...workflow.value, ...{ actions, owner: run.value.context.owner } }
      }
      return workflow.value
    })

    const log = computed(() => {
      return (
        Object.values(logs.value)
          .filter(l => l.action_id === actionId.value)
          .reverse()[0] || {}
      )
    })

    watch(
      () => action.value.type,
      (newType, oldType) => {
        // Improve reset of action (stange storage when action type change)
        if (oldType === 'user_validation' && newType !== 'user_validation') {
          action.value.onsuccess = {}
        }
        if (oldType === 'run_command' && newType !== 'run_command') {
          action.value.command = null
          action.value.rawOptions = null
        }
        if (oldType === 'wait_for' && newType !== 'wait_for') {
          action.value.url = null
        }

        if (newType === 'user_input' && !action.value.contentFolderId) {
          action.value.contentFolderId = defaultNarrativeContentFolder.value?.id || null
        }
      },
    )

    const initialName = ref(null)

    /**
     * Store initial workflow name, to be able to generate a unique name
     * TODO: Refactor component to unplug useWorkflow() value from v-model update
     **/
    const stopWatching = watch(
      () => workflow.value?.name,
      newValue => {
        if (newValue) {
          initialName.value = newValue
          stopWatching()
        }
      },
    )

    onMounted(async () => {
      initialName.value = workflow.value.name

      const narratives = await contentFolderService.findManyByType({ type: 'narrative' })
      narrativeContentFolders.value = narratives.map(narrative => ({ id: narrative.id, name: narrative.name }))

      defaultNarrativeContentFolder.value = narratives.find(
        narrative => narrative.name.toLowerCase() === 'management comment',
      )
    })

    return {
      run,
      rid,
      id,
      actionId,
      action,
      enrichedWorkflow,
      commands,
      periodComments,
      workflows,
      resetWorkflow,
      workflow,
      initialName,
      logs,
      log,
      commandr: config.commandr,
      impressions,
      users,
      isLoading,
      shouldConfirmClear,
      isOpenPopupAddUser,
      userToAdd,
      narrativeContentFolders,
      narrativeContentItem,
      isFetchingNarrative,
    }
  },
  components: {
    Board,
  },
  created() {
    const fn = () => {
      this.narrativeContentItem = null

      setTimeout(
        () =>
          document.querySelector('.action.selected') &&
          document.querySelector('.action.selected').scrollIntoView({ behavior: 'smooth' }),
        100,
      )
      setTimeout(this.fetchNarrativeContentItem, 500)
    }

    this.$watch('$root.query.action_id', fn)
    fn()

    this.$watch('log.status', () => {
      if (['user_input'].includes(this.action.type) && this.log.status === 'running')
        setTimeout(this.fetchNarrativeContentItem, 500)
    })
  },
  unmounted() {
    update_query(
      this.$route.query.map((d, k) => {
        ;['id', 'rid', 'action_id', 'tab'].includes(k) && null
      }),
    )
  },
  methods: {
    get(...args) {
      return get(...args)
    },
    setShouldConfirmClear(value) {
      this.shouldConfirmClear = value
    },
    async fetchNarrativeContentItem() {
      if (!this.action?.contentFolderId || this.action.type !== 'user_input' || this.log.status !== 'running') {
        return
      }

      this.isFetchingNarrative = true

      const contentFolder = await contentFolderService.getById({
        id: this.action.contentFolderId,
      })

      const narrativeVariables = Object.fromEntries(
        Object.entries(this.run.context).filter(([key]) => contentFolder.variables.includes(key)),
      )

      const contentFolderItem = await contentItemService.findOne({
        contentFolderId: this.action.contentFolderId,
        variables: { ...narrativeVariables },
      })

      this.narrativeContentItem = contentFolderItem || {
        variables: { ...narrativeVariables },
        value: '',
      }

      this.isFetchingNarrative = false
    },
    jsonParsedString(str) {
      if (!str || (str && str.trim() === '')) return {}
      let jsonResult
      try {
        jsonResult = JSON.parse(str)
      } catch (e) {}
      return jsonResult
    },
    getDocumentUrl(path) {
      return getAssetPublicUrl({ filenameWithPath: path })
    },
    async submitNarrativeContent(callback = () => {}) {
      if (!this.narrativeContentItem) {
        return callback()
      }

      const ko = () => $root.toast({ description: $root.t.save_narrative_failure, type: 'fail', timeout: 2000 })
      const ok = () => {
        $root.toast({ description: $root.t.save_narrative_success, type: 'success', timeout: 2000 })
        callback()
      }

      if (this.narrativeContentItem.id) {
        const response = await contentItemService.update({
          id: this.narrativeContentItem.id,
          contentItemInput: {
            value: this.narrativeContentItem.value,
            variables: this.narrativeContentItem.variables,
          },
        })

        return response?.id ? ok() : ko()
      } else {
        const response = await contentItemService.create({
          contentItemInput: {
            contentFolderId: this.action.contentFolderId || this.defaultNarrativeContentFolder.value?.id,
            value: this.narrativeContentItem.value,
            variables: { ...this.run.context },
          },
        })

        return response?.id ? ok() : ko()
      }
    },
    downloadExcelTemplate() {
      const { template, templateId } = this.run.context
      excelTemplateService.download(templateId).then(response => {
        downloadResponseAsFile(response, 'application/vnd.ms-excel', `preview-${template}-${templateId}.xlsx`)
      })
    },
    saveWorkflow() {
      const existingNames = this.workflows.flatMap(w => (w.name !== this.initialName ? [w.name] : []))
      const name = generateUniqueName(this.workflow.name, existingNames)

      set(`data.workflows.${this.workflow.id}`, { ...this.workflow, name })

      this.$router.push({ path: 'workflows' })
    },
    cancel() {
      this.$router.push({ path: 'workflows' })
    },
    resetForm() {
      this.resetWorkflow()
      this.shouldConfirmClear = false
    },
    addUser() {
      if (this.userToAdd) {
        const isValidEmail = /^(([^@]+)|(@[^@]+))@(([^@]+)|(@[^@]+))$/.test(this.userToAdd)
        if (!isValidEmail) {
          $root.toast({ description: $root.t.invalid_email, type: 'error', timeout: 2000 })
        } else {
          set(`data.users.${Object.keys(this.users).length}`, { email: this.userToAdd })
          this.userToAdd = ''
          this.isOpenPopupAddUser = false
        }
      }
    },
    cancelAddUser() {
      this.isOpenPopupAddUser = false
      this.userToAdd = ''
    },
  },
  computed: {
    boardData() {
      return [{ name: 'Preview', url: 'preview' }]
        .concat(
          (this.workflow &&
            this.workflow.actions
              .v()
              .filter(a => ['wait_for', 'user_input', 'run_command'].includes(a.type) && a.url)) ||
            [],
        )
        .concat({ name: 'Final Document', url: 'final' })
        .reverse()
    },
    download_url() {
      const url = getBuilderPreviewUrl({
        isDataReport: !!this.run.context.dataReportId,
        run: {
          ...this.run.context,
          id: this.run.id,
          fundId: this.run.context.fund_id,
          shareId: this.run.context.share_id,
        },
      })

      return url
    },
  },
}
</script>
