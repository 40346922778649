<template>
  <builder-block class="builder-sri" :data="data" :options="options" :context="context">
    <div class="sri-container flex flex-col">
      <div class="mb-1 mr-1 flex items-center space-x-2">
        <div v-if="options.showLabel" class="sri-label">
          <span class="flex items-center justify-center">
            {{ translate('sri') }}
          </span>
        </div>
        <div>
          <div class="sri-svg-container flex items-center first:pl-[1px]">
            <span
              v-for="i in [1, 2, 3, 4, 5, 6, 7]"
              :key="i"
              :style="{ paddingLeft: `${getVariant(options.shape).space}px` }"
              :class="i === sri ? 'svg-value' : 'svg-normal'"
            >
              <svg
                :width="
                  options.shape === 'circle'
                    ? getVariant(options.shape).circleRadius + getVariant(options.shape).space
                    : getVariant(options.shape).width + getVariant(options.shape).space
                "
                :height="
                  options.shape === 'circle'
                    ? getVariant(options.shape).circleRadius + getVariant(options.shape).space
                    : getVariant(options.shape).height + getVariant(options.shape).space
                "
                :class="`text-primary`"
                :viewBox="`0 0 ${options.shape === 'circle' ? getVariant(options.shape).circleRadius : getVariant(options.shape).width} ${options.shape === 'circle' ? getVariant(options.shape).circleRadius : getVariant(options.shape).height}`"
                xmlns="http://www.w3.org/2000/svg"
              >
                <template v-if="options.shape === 'circle'">
                  <circle
                    cx="50%"
                    cy="50%"
                    :r="getVariant(options.shape).circleRadius / 2 - getVariant(options.shape).space / 2"
                    :stroke-width="getVariant(options.shape).space"
                    class="shape sri-circle"
                  ></circle>
                </template>
                <template v-else-if="options.shape === 'square'">
                  <rect
                    x="0"
                    y="0"
                    width="100%"
                    height="100%"
                    :stroke-width="getVariant(options.shape).space"
                    class="shape"
                  ></rect>
                </template>
                <template v-else-if="options.shape === 'round square'">
                  <path
                    :d="`M${0.747 * getVariant(options.shape).width} 0H${0.253 * getVariant(options.shape).width}A${0.253 * getVariant(options.shape).width} ${0.253 * getVariant(options.shape).height} 0 0 0 0 ${0.253 * getVariant(options.shape).height}v${0.4935 * getVariant(options.shape).height}a${0.253 * getVariant(options.shape).width} ${0.253 * getVariant(options.shape).height} 0 0 0 ${0.253 * getVariant(options.shape).width} ${0.253 * getVariant(options.shape).height}h${0.4935 * getVariant(options.shape).width}a${0.253 * getVariant(options.shape).width} ${0.253 * getVariant(options.shape).height} 0 0 0 ${0.253 * getVariant(options.shape).width} -${0.253 * getVariant(options.shape).height}V${0.253 * getVariant(options.shape).height}A${0.2537 * getVariant(options.shape).width} ${0.2537 * getVariant(options.shape).height} 0 0 0 ${0.747 * getVariant(options.shape).width} 0z`"
                    :stroke-width="getVariant(options.shape).space"
                    class="shape"
                  />
                </template>
                <text class="sri-value" x="50%" y="57%" dominant-baseline="middle" text-anchor="middle">
                  {{ i }}
                </text>
              </svg>
            </span>
          </div>
          <div
            v-if="options.showLegend && options.legendPosition === 'top'"
            class="sri-legend flex items-center justify-between"
            :style="`width: ${(options.shape === 'circle' ? getVariant(options.shape).circleRadius + getVariant(options.shape).space : getVariant(options.shape).width + getVariant(options.shape).space) * 7 + getVariant(options.shape).space * 7}px;
            padding-left: ${getVariant(options.shape).space + 2}px`"
          >
            <span>{{ translate('low_risk') }}</span>
            <span>{{ translate('high_risk') }}</span>
          </div>
          <div class="sri-arrow-container">
            <div class="arrow mt-1 flex items-center" v-if="options.displayArrow && options.arrowType === 'simple'">
              <svg
                width="100%"
                :height="getVariant(options.shape).arrowHeadSize * 2"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  :x1="getVariant(options.shape).space + 2"
                  :y1="getVariant(options.shape).arrowHeadSize"
                  :x2="
                    (options.shape === 'circle'
                      ? getVariant(options.shape).circleRadius + getVariant(options.shape).space
                      : getVariant(options.shape).width + getVariant(options.shape).space) *
                      7 +
                    getVariant(options.shape).space * 7 -
                    2
                  "
                  :y2="getVariant(options.shape).arrowHeadSize"
                />
                <path
                  :d="`M${getVariant(options.shape).space + 2 + getVariant(options.shape).arrowHeadSize * 2},0 L${getVariant(options.shape).space + 2},${getVariant(options.shape).arrowHeadSize} L${getVariant(options.shape).space + 2 + getVariant(options.shape).arrowHeadSize * 2}, ${getVariant(options.shape).arrowHeadSize * 2}`"
                  stroke-linejoin="round"
                  fill="none"
                />
                <path
                  :d="`M${(options.shape === 'circle' ? getVariant(options.shape).circleRadius + getVariant(options.shape).space : getVariant(options.shape).width + getVariant(options.shape).space) * 7 + getVariant(options.shape).space * 7 - (getVariant(options.shape).arrowHeadSize * 2 + 2)},0 L${(options.shape === 'circle' ? getVariant(options.shape).circleRadius + getVariant(options.shape).space : getVariant(options.shape).width + getVariant(options.shape).space) * 7 + getVariant(options.shape).space * 7 - 2},${getVariant(options.shape).arrowHeadSize} L${(options.shape === 'circle' ? getVariant(options.shape).circleRadius + getVariant(options.shape).space : getVariant(options.shape).width + getVariant(options.shape).space) * 7 + getVariant(options.shape).space * 7 - (getVariant(options.shape).arrowHeadSize * 2 + 2)},${getVariant(options.shape).arrowHeadSize * 2}`"
                  stroke-linejoin="round"
                  fill="none"
                />
              </svg>
            </div>
            <div class="arrow mt-1 flex items-center" v-else-if="options.displayArrow && options.arrowType === 'fill'">
              <svg width="100%" height="10" xmlns="http://www.w3.org/2000/svg">
                <line
                  :x1="getVariant(options.shape).space + 2"
                  :y1="getVariant(options.shape).arrowHeadSize"
                  :x2="
                    (options.shape === 'circle'
                      ? getVariant(options.shape).circleRadius + getVariant(options.shape).space
                      : getVariant(options.shape).width + getVariant(options.shape).space) *
                      7 +
                    getVariant(options.shape).space * 7 -
                    2
                  "
                  :y2="getVariant(options.shape).arrowHeadSize"
                />
                <path
                  :d="`M${getVariant(options.shape).space + 2 + getVariant(options.shape).arrowHeadSize * 2},0 L${getVariant(options.shape).space + 2},${getVariant(options.shape).arrowHeadSize} L${getVariant(options.shape).space + 2 + getVariant(options.shape).arrowHeadSize * 2},${getVariant(options.shape).arrowHeadSize * 2} L${getVariant(options.shape).space + 2 + getVariant(options.shape).arrowHeadSize * 2},0 Z`"
                  stroke-linejoin="round"
                />
                <path
                  :d="`M${(options.shape === 'circle' ? getVariant(options.shape).circleRadius + getVariant(options.shape).space : getVariant(options.shape).width + getVariant(options.shape).space) * 7 + getVariant(options.shape).space * 7 - (getVariant(options.shape).arrowHeadSize * 2 + 2)},0 L${(options.shape === 'circle' ? getVariant(options.shape).circleRadius + getVariant(options.shape).space : getVariant(options.shape).width + getVariant(options.shape).space) * 7 + getVariant(options.shape).space * 7 - 2},${getVariant(options.shape).arrowHeadSize} L${(options.shape === 'circle' ? getVariant(options.shape).circleRadius + getVariant(options.shape).space : getVariant(options.shape).width + getVariant(options.shape).space) * 7 + getVariant(options.shape).space * 7 - (getVariant(options.shape).arrowHeadSize * 2 + 2)},${getVariant(options.shape).arrowHeadSize * 2} L${(options.shape === 'circle' ? getVariant(options.shape).circleRadius + getVariant(options.shape).space : getVariant(options.shape).width + getVariant(options.shape).space) * 7 + getVariant(options.shape).space * 7 - (getVariant(options.shape).arrowHeadSize * 2 + 2)},0 Z`"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <div
            v-if="options.showLegend && options.legendPosition === 'bottom'"
            class="sri-legend flex items-center justify-between"
            :style="`width: ${(options.shape === 'circle' ? getVariant(options.shape).circleRadius + getVariant(options.shape).space : getVariant(options.shape).width + getVariant(options.shape).space) * 7 + getVariant(options.shape).space * 7}px; padding-left: ${getVariant(options.shape).space + 2}px`"
          >
            <span>{{ translate('low_risk') }}</span>
            <span>{{ translate('high_risk') }}</span>
          </div>
        </div>
      </div>
    </div>
  </builder-block>
</template>
<script setup lang="ts">
const { data, options, context } = defineProps(['data', 'options', 'context'])
import { sri as story } from './stories'
function translate(value: string) {
  return context.translations[value] || value
}

const sri = +data?.sri || +data?.srri

// hard code data waiting for component page
const variants = {
  circle: {
    circleRadius: 25,
    space: 1,
    strokeSize: 2,
    arrowHeadSize: 2,
  },
  square: {
    height: 25,
    width: 25,
    space: 1,
    strokeSize: 2,
    arrowHeadSize: 2,
  },
  'round square': {
    height: 10,
    width: 10,
    space: 5,
    strokeSize: 0,
    arrowHeadSize: 2,
  },
}

// fallback to default shape if shape not loaded
function getVariant(shape: string) {
  return variants[shape] || variants['circle']
}
</script>
<script lang="ts">
export default {
  api: {
    showLabel: {
      label: 'Show Label',
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
      default: () => true,
    },
    shape: {
      label: 'Shape',
      default: () => 'circle',
      select: () => {
        return ['circle', 'square', 'round square']
      },
      attrs: {
        type: 'text',
      },
    },
    displayArrow: {
      label: 'Display Arrow',
      default: () => false,
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
    },
    arrowType: {
      label: 'Arrow Type',
      default: () => 'simple',
      select: () => {
        return ['simple', 'fill']
      },
      hide: ({ options }) => !options?.displayArrow,
      attrs: {
        type: 'text',
      },
    },
    showLegend: {
      label: 'Show Legend',
      default: () => false,
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
    },
    legendPosition: {
      label: 'Legend Position',
      default: () => 'top',
      select: () => {
        return ['top', 'bottom']
      },
      hide: ({ options }) => !options?.showLegend || !options?.displayArrow,
      attrs: {
        type: 'text',
      },
    },
  },
  styles: {
    '.sri-container': {
      name: 'Container',
      css: `font-size: 12px;`,
    },
    '.sri-value': {
      name: 'Value',
      css: ``,
    },
    '.sri-svg-container': {
      name: 'SVG Container',
      css: `.svg-normal .shape{ fill:white; }
      .svg-value .shape{ fill: var(--palette-1, var(--primary)); }
      .shape { stroke: black;}`,
    },
    '.sri-legend': {
      name: 'Legend',
      css: `font-size: 9px;`,
    },
    '.sri-arrow-container': {
      name: 'Arrow Container',
      css: `fill:black;  stroke:black;`,
    },
  },
  story,
}
</script>
