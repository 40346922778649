import { watch } from 'vue'
/**
 * Generate chartjs chartOptions according options and chartOptions
 * @param options
 * @param tooltip
 * @param legend
 * @return {{chartOptions: {plugins: {legend: {align: string, labels: {usePointStyle: boolean}}, tooltip: *}, elements: {line: {tension: number}, point: {pointStyle: string, radius: number}}, scales: {x: {grid: {display: boolean}, time: {unit: string}, type: string}, y: {ticks: {callback: function(*=, *, *): (*|undefined)}}}, interaction: {intersect: boolean, axis: string}}}}
 */
export default function (options, { tooltip, legends } = {}) {
  const datalabels =
    options.value.datalabels === true
      ? {
          align: 'top',
          anchor: 'end',
          formatter: options.value.labelFormatter,
        }
      : undefined

  const callbackY = options.value.formatY ? value => options.value.formatY(value) : value => value
  const callbackX = options.value.formatX ? value => options.value.formatX(value) : value => value
  const chartOptions = {
    plugins: {
      datalabels,
      tooltip: !options.value.hideExternalTooltip && tooltip,
      legend: {
        display: false,
      },
      customLegend: {
        legends,
      },
    },
    elements: {
      line: {
        tension: 0,
      },
      bar: {},
      point: {
        pointStyle: 'point',
        radius: 3,
      },
    },
    interaction: {
      intersect: false,
      axis: 'xy',
      mode: 'nearest',
    },
    scales: {
      y: {
        ticks: {
          callback: callbackY,
        },
      },
      x: {
        ticks: {
          callback: callbackX,
        },
      },
    },
    ...options.value.mergeableOptions,
  }

  const setScale = (labels, scalesCallback, title) => {
    if (labels && labels.length > 0) {
      return {
        title: {
          display: true,
          text: title,
          font: {
            size: options.value.titleTextSize || 12,
            weight: options.value.titleTextWeight || 'normal',
          },
        },
        min: -1,
        max: labels.length,
        ticks: {
          callback: function (val, index) {
            if (val < 0 || Math.floor(val) !== val) {
              return
            }
            return labels.at(val) || null
          },
        },
      }
    }
    return {
      title: {
        display: true,
        text: title,
        font: {
          size: options.value.titleTextSize || 12,
          weight: options.value.titleTextWeight || 'normal',
        },
      },
      beginAtZero: true,
      ticks: {
        callback: scalesCallback,
      },
    }
  }

  const setScales = () => {
    chartOptions.scales = {
      y: setScale(options.value.yLabels, callbackY, options.value.yAxisTitle),
      x: setScale(options.value.xLabels, callbackX, options.value.xAxisTitle),
    }
  }

  setScales()
  watch(options, setScales, { deep: false })

  return {
    chartOptions,
  }
}
