<template>
  <popup :show="show">
    <template #header>
      {{
        props.stylesheetToDuplicateId
          ? $root.t.create_stylesheet_popup.duplicate_title
          : $root.t.create_stylesheet_popup.create_title
      }}
    </template>
    <template #content>
      <div class="form">
        <label>
          {{ $root.t.create_stylesheet_popup.stylesheet_name_label }}
          <input
            type="text"
            style="width: 100%"
            v-model="createStylesheetForm.name"
            :placeholder="$root.t.create_stylesheet_popup.stylesheet_name_placeholder"
          />
        </label>
        <span v-if="formErrors.name" class="error">{{ formErrors.name }}</span>
      </div>
    </template>
    <template #action>
      <button class="secondary-action" @click="closePopup">{{ $root.t.cancel }}</button>
      <button class="main-action" @click="createStylesheet" :disabled="!createStylesheetForm.name">
        {{ $root.t.create_stylesheet_popup.create }}
      </button>
    </template>
  </popup>
</template>

<script setup lang="ts">
import { ref, defineEmits, onMounted, watch } from 'vue'
import popup from '@100-m/hauru/src/components/ui/popup.vue'
import stylesheetService from '@100-m/hauru/src/services/StylesheetService'
import { Stylesheet } from '../../../builder/builder'
import { z } from 'zod'

const props = defineProps<{
  show: boolean
  stylesheetToDuplicateId: number
}>()

const emit = defineEmits(['create-stylesheet', 'close'])

const stylesheetToDuplicate = ref<Stylesheet | null>(null)

const createStylesheetFormSchema = z.object({
  name: z
    .string()
    .min(1)
    .regex(/^[a-zA-Z0-9 _\-()]*$/, {
      message: $root.t.create_stylesheet_popup.stylesheet_name_error,
    }),
})

const createStylesheetForm = ref<{ name: string | null }>({
  name: null,
})

const formErrors = ref<{ name: string | null }>({ name: null })

const validateForm = () => {
  const result = createStylesheetFormSchema.safeParse({ name: createStylesheetForm.value.name })
  formErrors.value.name = result.success ? null : result.error.errors[0].message
}

const fetchData = async () => {
  if (props.stylesheetToDuplicateId) {
    stylesheetToDuplicate.value = await stylesheetService.getById({ id: props.stylesheetToDuplicateId })

    createStylesheetForm.value = {
      name: 'Copy - ' + stylesheetToDuplicate.value.name,
    }
  }
}

onMounted(fetchData)

watch(
  () => props.stylesheetToDuplicateId,
  async (newVal, oldVal) => {
    if (newVal !== oldVal) {
      await fetchData()
    }
  },
)

const closePopup = () => {
  stylesheetToDuplicate.value = null

  emit('close')
}

const createStylesheet = () => {
  validateForm()

  if (formErrors.value.name) {
    return
  }

  emit('create-stylesheet', {
    ...(stylesheetToDuplicate.value && { ...stylesheetToDuplicate.value }),
    ...createStylesheetForm.value,
  })

  stylesheetToDuplicate.value = null
}
</script>

<style scoped>
.form {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
}
.error {
  color: red;
  font-size: 12px;
}
</style>
