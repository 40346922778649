<template>
  <div class="-page-margin w-[calc(100%+var(--page-margin)*2)]">
    <div class="section-container flex items-stretch pt-[10%]">
      <div class="section-title flex h-full flex-1 items-center px-10 py-4">
        {{ translate(props.options?.title) }}
      </div>
      <div class="section-side-bar"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useTranslations from '../composables/translations'
import { section } from './stories'

const props = defineProps(['data', 'options', 'context'])
const { translate } = useTranslations(props as any)
</script>

<script lang="ts">
export default {
  styles: {
    'section-container': `padding-top: 10%;
width: 83%;
`,
    'section-title': `background-color: var(--primary);
font-size: 30px;
font-weight: bold;
`,
    'section-side-bar': `width: 20px;
background-color: var(--secondary);
`,
  },
  story: section,
}
</script>
