<template>
  <loading-icon v-if="loading" :size="36" style="margin: auto; padding: 12px 0" />
  <div v-else class="user-input-actions">
    <label>{{ `${t.instruction} - ${contentFolder?.name || 'Management comment'}` }}</label>
    <textarea v-model="narrativeContentItem.value"></textarea>

    <div class="row action-buttons">
      <button class="ac primary" @click.stop="submitComment">
        <div class="row nowrap">
          <div class="column">
            <svg-icon class="icon" name="pt-icon-tick" />
          </div>
          <div class="column">{{ t.submit }}</div>
        </div>
      </button>
      <button class="ac secondary" @click.stop="rejectComment">
        <div class="row nowrap">
          <div class="column">
            <svg-icon class="icon" name="pt-icon-cross" />
          </div>
          <div class="column">{{ t.reject }}</div>
        </div>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits, onMounted, watch } from 'vue'
import contentFolderService, { ContentFolder } from '@100-m/hauru/src/services/ContentFolderService'
import contentItemService, { ContentItem } from '@100-m/hauru/src/services/ContentItemService'

const props = defineProps<{
  run: any
  contentFolderId: number
}>()

const narrativeContentItem = ref<ContentItem | { value: string }>({ value: '' })
const loading = ref<boolean>(false)
const contentFolder = ref<ContentFolder | null>(null)

const emit = defineEmits<{
  (e: 'submit', payload: { narrativeContentItem: ContentItem | { value: string } }): void
  (e: 'reject'): void
}>()

const initializeContent = async () => {
  loading.value = true

  await fetchNarrativeContentFolder()
  await fetchNarrativeContentItem()

  loading.value = false
}

onMounted(initializeContent)

watch([() => props.run, () => props.contentFolderId], initializeContent)

const submitComment = () => {
  emit('submit', { narrativeContentItem: narrativeContentItem.value })
}

const rejectComment = () => {
  emit('reject')
}

const fetchNarrativeContentFolder = async () => {
  if (!props.contentFolderId) {
    return
  }

  const _contentFolder = await contentFolderService.getById({ id: props.contentFolderId })

  contentFolder.value = _contentFolder
}

const fetchNarrativeContentItem = async () => {
  if (!contentFolder.value) {
    return null
  }

  const narrativeVariables = Object.fromEntries(
    Object.entries(props.run.context).filter(([key]) => contentFolder.value?.variables?.includes(key)),
  )

  const contentFolderItem = await contentItemService.findOne({
    contentFolderId: contentFolder.value.id,
    variables: narrativeVariables,
  })

  narrativeContentItem.value = contentFolderItem || {
    variables: narrativeVariables,
    value: '',
  }
}
</script>

<style scoped>
.user-input-actions {
  width: 100%;
}

.icon {
  margin-right: 8px;
  fill: var(--primary);
}

.ac.primary .icon {
  fill: white;
}

.action-buttons {
  width: 100%;
  justify-content: space-around;
  margin-top: 18px;
}
</style>
