<template>
  <component
    :is="errorComponent"
    :data="error"
    :options="node.options || {}"
    :context="{ data, layout, store, node, ...context }"
    v-if="error"
  ></component>
  <component
    v-else-if="children"
    :style="style"
    :disabled="node.theme || !store.active.length"
    :is="componentTag"
    v-bind="componentProps"
    :class="[containerClass, virtual && 'is-virtual']"
    item-key="path"
  >
    <tooltip-helper class="no-print p-2" v-if="virtual && path.length === 1 && !isPreviewMode">
      <template #icon="{ updateDisplay }">
        <span @mouseover="updateDisplay" @mouseleave="updateDisplay">Overflow page</span>
      </template>
      <div>
        <p>This is an overflow page, created by the component above overflowing.</p>
        <p>It cannot be interacted with, modifiy the parent component to edit.</p>
      </div>
    </tooltip-helper>
    <template v-for="(element, index) in children" :key="index">
      <builder
        :class="[
          element.virtualPath ? 'blockid-' + element.virtualPath.join('-') : 'blockid-' + getNodePath(index).join('-'),
          element?.data?.format('dash'),
          node.type === 'overlay' && index !== 0 ? 'absolute' : '',
          element?.options?.class,
          isActive(getNodePath(index)) ? 'z-10 rounded-md ring-4 ring-yellow-400' : '',
          childIsActive(getNodePath(index)) ? 'z-10 rounded-md ring-4 ring-yellow-200' : '',
        ]"
        :node="element"
        :data="data"
        :layout="layout"
        :path="getNodePath(index)"
        :virtualPath="element.virtualPath"
        :store="store"
        :context="context"
        :parentNode="node"
        :virtual="virtual"
        :isPreviewMode="isPreviewMode"
        @mousedown.stop="onClick(index, element)"
        @active="$emit('active', $event)"
        @end="$emit('end', $event)"
        @overflow="emit('overflow', $event)"
      ></builder>
    </template>
  </component>
  <!-- <div
    :class="[
      isActive(path) ? 'z-10 rounded-md ring-4 ring-yellow-400' : '',
      childIsActive(path, store.active.slice(0, -2)) ? 'z-10 rounded-md ring-4 ring-yellow-200' : '',
    ]"
    v-else
  >
    {{ componentProps.options }}
  </div> -->
  <component
    :style="style"
    :is="componentTag"
    :class="[
      'pdf-block',
      ...componentClasses,
      isActive(path) ? 'z-10 rounded-md ring-4 ring-yellow-400' : '',
      childIsActive(path) ? 'z-10 rounded-md ring-4 ring-yellow-200' : '',
    ]"
    v-bind="componentProps"
    v-else-if="componentProps && !hideOverflowing && !isEmptyData(componentProps.data)"
  ></component>
  <builder-block
    v-else-if="componentProps && isEmptyData(componentProps.data) && !isPreviewMode"
    :style="style"
    :class="[
      'pdf-block',
      ...componentClasses,
      isActive(path) ? 'z-10 rounded-md ring-4 ring-yellow-400' : '',
      childIsActive(path) ? 'z-10 rounded-md ring-4 ring-yellow-200' : '',
    ]"
    v-bind="componentProps"
  >
    <div class="flex h-full w-full flex-col items-center justify-center">
      <div class="i-carbon/warning-other !h-16 !w-16 text-gray-300"></div>
      <div class="py-4 text-center leading-snug text-gray-600">
        No data for these parameters. This will not be displayed when the template is generated.
      </div>
    </div>
  </builder-block>
</template>

<script setup>
import { ref, computed, onErrorCaptured, onMounted, nextTick } from 'vue'
import { useComponents } from '../composables/builderComponent'
import useLayout from '../composables/layout'
const { getComponentTag, getComponent, getHeaderComponent } = useComponents()
const props = defineProps([
  'node',
  'data',
  'layout',
  'store',
  'context',
  'path',
  'parentNode',
  'virtual',
  'virtualPath',
  'isPreviewMode',
])
const layout = computed(() => props.layout)
const { getNode, pageDel } = useLayout(props.store, layout)
const emit = defineEmits(['overflow', 'active'])
const error = ref(false)

function isEmptyData(data) {
  if (!props.node.data) return false
  return !data || (Array.isArray(data) && !data.length) || (typeof data === 'object' && !Object.keys(data).length)
}
const getNodePath = index => {
  return [...props.path, index]
}

function onClick(index, element) {
  if (error.value) return
  if (props.virtual) {
    if (!element.virtualPath) return
    emit('active', element.virtualPath)
  } else {
    emit('active', getNodePath(index))
  }
}

const isActive = path => {
  return path.join('.') === props.store.active.join('.')
}
const childIsActive = path => {
  return path.join('.') === props.store.active.slice(0, -2).join('.')
}
const containerClass = computed(() => {
  if (props.node.component) return 'pdf-block'
  if (props.node.type === 'row') return 'pdf-line'
  if (props.node.type === 'column') return 'pdf-column'
  if (props.node.type === 'overlay') return 'pdf-overlay'
  return 'pdf-block'
})

const style = computed(() => {
  if (props?.parentNode?.type === 'overlay') {
    const style = { ...props.node?.position }
    if (props.node.width === 'fit') {
      style.width = 'fit-content'
    }
    if (props.node.height) {
      style['max-height'] = props.node.height
      style['min-height'] = props.node.height
    }
    if (!props.node.height) {
      style['min-height'] = 'fit-content'
    }
    return style
  }
  const style = {}
  if (props.node.width === 'fit' || props.node.height === 'fit') {
    style.flex = '0 1 min-content'
  }
  if (props.node.width) {
    style['max-width'] = `calc(${props.node.width} - var(--spacing) * 1/2)`
    style['min-width'] = `calc(${props.node.width} - var(--spacing) * 1/2)`
  }
  if (props.node.height) {
    style['max-height'] = `calc(${props.node.height} - var(--spacing) * 1/2)`
    style['min-height'] = `calc(${props.node.height} - var(--spacing) * 1/2)`
  }
  if (props.node.margin && !isNaN(+props.node.margin)) {
    style.margin = +props.node.margin * 0.25 + 'rem'
  }
  return style
})

const errorComponent = computed(() => {
  if (!props.layout) return 'builder-error'
  // TODO: manage global components
  return getComponentTag('error')
})

const componentTag = ref('div')
function setComponentTag() {
  if (!props.node.component) {
    componentTag.value = 'div'
    return
  }
  if (props.node.component === 'header') {
    componentTag.value = getHeaderComponent(props.layout?.theme?.header)
    return
  }
  componentTag.value = getComponentTag(props.node.component)
}
const componentClasses = computed(() => {
  if (!props.node.component) return []
  const generalClass = 'builder-' + props.node.component
  if (componentTag.value !== generalClass) {
    return [generalClass, componentTag.value]
  }
  return [generalClass]
})
function getNodeData() {
  if (!props.node.component) return
  const dataPath = (props.node.data || '').split('.')
  const dataValue = props.data[dataPath[0]]?.[dataPath.slice(1).join('.')]
  return dataValue
}

function parseNodeData(node, nodeData, api) {
  if (!nodeData?.vizType) {
    return { data: nodeData, options: getOptions(node, nodeData, api) }
  }
  return {
    data: nodeData.data,
    options: { ...getOptions(node, nodeData, api), ...nodeData.options },
  }
}
function getOptions(node, data, api) {
  // TODO: avoid doing JSON parse stringify
  const options = node.options ? JSON.parse(JSON.stringify(node.options)) : {}
  return options
}

function getPostFormatFn(postFormatName) {
  if (!postFormatName) return
  return config?.customFormats?.[postFormatName]
}
const componentProps = ref(null)
function isLastOnPage(path, layout) {
  const page = layout.nodes[path[0]]
  const innerPath = path.slice(1)
  return innerPath.every((index, i) => {
    const node = innerPath.slice(0, i).reduce((acc, v) => acc.nodes[v], page)
    return node?.nodes?.filter(d => d?.component !== 'footer').length - 1 === index
  })
}
function setComponentProps(data, options, nodeData) {
  const stylesheet = props.layout?.theme?.loadedStylesheets?.[props.node?.stylesheet || props.layout?.theme?.stylesheet]
  const isLast = isLastOnPage(props.path, props.layout)
  const postFormat = getPostFormatFn(props.layout?.theme?.postFormat)
  componentProps.value = {
    data,
    options,
    context: {
      data: props.data,
      vizType: nodeData?.vizType,
      layout: props.layout,
      postFormat,
      footnotes: props.node.footnotes || [],
      pageFootnotes: props.layout.nodes[props.path[0]]?.footnotes || [],
      globalFootnotes: props.layout.footnotes || [],
      stylesheet,
      node: props.node,
      isLast,
      path: props.path,
      virtual: props.virtual,
      ...props.context,
    },
  }
}
const updateComponentProps = () => {
  // if (_componentProps) return _componentProps
  // if (!props.node.component) return {}
  // const api = components.value[componentTag.value]?.api
  // If the dataPath changes, reset the options
  // if (!props.node?.options || props.node?.options?.dataPath !== props.node.data) {
  //   props.node.options = { dataPath: props.node.data }
  // }
  const component = getComponent(props.node.component)
  const nodeData = getNodeData()
  const { data, options } = parseNodeData(props.node, nodeData, component?.api)
  setComponentProps(data, options, nodeData)
  setComponentTag()
}

function shouldUpdate(eventPath, path) {
  return eventPath.every((v, i) => v === path[i])
}
onMounted(() => {
  updateComponentProps()
  window.addEventListener('builderUpdate', e => {
    // console.log('Update event', e.detail.path, props.path)
    if (shouldUpdate(e.detail.path, props.path)) {
      hideOverflowing.value = false
      updateComponentProps()
    }
    if (props.virtualPath) {
      if (shouldUpdate(e.detail.path, props.virtualPath)) {
        const node = getNode(props.virtualPath)
        const virtualNode = getNode(props.path)
        // If the parent data or component changed, delete the virtual node
        if (node?.component !== virtualNode?.component || node?.data !== virtualNode?.data) {
          return nextTick(() => pageDel(props.path, false))
        }
        const component = getComponent(props.node.component)
        const nodeData = getNodeData()
        const { data, options } = parseNodeData(props.node, nodeData, component?.api)
        setComponentProps(data, { ...options, ...node.options }, nodeData)
      }
    }
  })
  window.addEventListener('builderOverflow', e => {
    if (shouldUpdate(e.detail.path, props.path)) {
      console.log('builderOverflow', e.detail)
      console.log('shouldUpdate', shouldUpdate(e.detail.path, props.path))
      onOverflow(e.detail)
    }
  })
})
// Step 1 Render once no update
// Step 2 global
// onEvent (ev => {
//   if (ev.path = myPath) {
//     if component updateComponentProps()
//     // if layout updateLayout()
// })
const children = computed(() => {
  return props.node.nodes || null
})

const hideOverflowing = ref(false)
function onOverflow({ isOverflow, end }) {
  const component = props.node?.component
  const hideOverflowComponents = ['text']
  const truncOverflowComponents = ['table']
  if (hideOverflowComponents.includes(component)) {
    hideOverflowing.value = isOverflow
    emit('overflow', { path: props.path, isOverflow, component })
  }
  if (truncOverflowComponents.includes(component)) {
    console.log('Overflow builder', props.path, props.virtualPath, props.virtual, end)
    emit('overflow', { path: props.path, isOverflow, component, end, virtual: props.virtual })
  }
}

onErrorCaptured(e => {
  error.value = e
  return false
})
</script>
<style scoped>
.tooltip-helper {
  position: absolute;
  left: 0;
}
.tooltip-helper :deep(.tooltip-content) {
  left: 0;
  transform: none;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
.is-virtual {
  cursor: not-allowed;
}
</style>
