import {
  IGridColumnsMetadata,
  useHashList,
  IGridStateLimited,
  IGridHeader,
  IGridColumnsState,
  IGridCellData,
} from '@hauru/common'
import { reactiveComputed } from '@vueuse/core'
import { isFunction } from 'lodash'
import { reactive, readonly } from 'vue'

export type IGridHeaderState = ReturnType<typeof useHeader>

export type IHeaderList = ReturnType<typeof useHeaderList>

export function useHeaderList() {
  return useHashList<Readonly<IGridHeader>>({
    getKey: item => item.label,
  })
}

export function useHeader(
  state: IGridStateLimited,
  columns: IGridColumnsState,
  metadata: IGridColumnsMetadata | undefined,
  parentHeader: IGridHeader | undefined,
  newWidth: number,
  key: string,
  labels: string[] = [],
  mark: number,
): Readonly<IGridHeader> {
  const columnMetadata = metadata?.[key]

  const columnHeader: IGridHeader = reactive({
    label: key,
    labelPathArray: [...labels, key],
    labelPath: [...labels, key].join('/'),
    parent: parentHeader,

    width: columnMetadata?.width ?? newWidth,
    setWidth,
    isAutoSized: false,
    setAutoSized: (value: boolean) => (columnHeader.isAutoSized = value),
    autoSize,
    widthPercent: 0,
    setWidthPercent,
    show: columnMetadata?.show ?? (!columns.excludeId || columns.idColumn !== key),
    toggleShow,

    showWhenExpanded: true,
    toggleShowWhenExpanded,
    showWhenCollapsed: false,
    toggleShowWhenCollapsed,

    isExpanded: columnMetadata?.is_expanded ?? true,
    toggleExpanded,
    isExpandable: { value: false },

    visible: [],
    visibleOrDefaultCollapsed: [],
    recalculateVisible,

    collapsedColumns: [],
    setCollapsedColumns: (columns: string[]) => (columnHeader.collapsedColumns = columns),

    mark,
    setMark,

    children: useHeaderList(),
    columnCount: 1,
    setColumnCount,
    sort,
    isId: columns.idColumn === key,

    index: 0, // Value recalculated by useColumns
    setIndex: (index: number) => (columnHeader.index = index),
    level: 0, // Value recalculated by useColumns
    setLevel: (level: number) => (columnHeader.level = level),
    indexPath: [], // Value recalculated by useColumns
    setIndexPath: (indexPath: number[]) => (columnHeader.indexPath = indexPath),

    findRightmostVisible,
    getCell,
    setLabel,
  })
  columnHeader.isExpandable = reactiveComputed(() => {
    return {
      value: !!(columnHeader?.children.list.length && columnHeader.collapsedColumns.length),
    }
  })

  recalculateVisible()
  function recalculateVisible() {
    columnHeader.visible = columnHeader.children.list.filter(child => {
      return child.show && (columnHeader.isExpanded ? child.showWhenExpanded : child.showWhenCollapsed)
    }) as IGridHeader[]
    columnHeader.visibleOrDefaultCollapsed = columnHeader.visible
    // TODO : ADD state variable controling whether solo child should show when collapsed
    if (columnHeader.collapsedColumns.length === 1 && !columnHeader.isExpanded) {
      columnHeader.visible = []
      columnHeader.visibleOrDefaultCollapsed = [columnHeader.children.get(columnHeader.collapsedColumns[0])!]
    }
  }

  function sort(sortFunction: (a: IGridHeader, b: IGridHeader) => number) {
    columnHeader.children.sort(sortFunction)
  }

  function setMark(mark: number) {
    return (columnHeader.mark = mark)
  }

  function autoSize() {
    if (isFunction(state.autosizeColumn)) return state.autosizeColumn(state, columnHeader)
    else {
      if (!state.ref) return 0
      const cells = µµ(
        `[nx-header-container='${columnHeader.index}'] [nx-header], [nx-cell-column='${columnHeader.index}'] *`,
        state.ref,
      )
      if (cells.length <= 1) return 0
      const width = Math.max(...cells.map(el => el.scrollWidth)) + 18
      if (state.data.length) columnHeader.isAutoSized = true
      return columnHeader.setWidth(Math.min(state.ref.clientWidth * 0.75, width))
    }
  }

  function setLabel(newLabel: string) {
    columnHeader.label = newLabel
    columnHeader.labelPathArray = [...labels, newLabel]
    columnHeader.labelPath = [...labels, newLabel].join('/')
  }

  function setWidth(width: number) {
    columnHeader.width = Math.max(columns.widthMin, width)
    columns.registerResize()
    return columnHeader.width
  }

  function setWidthPercent(widthPercent: number) {
    return (columnHeader.widthPercent = widthPercent)
  }

  function toggleShow(value?: boolean, recalculate = true) {
    columnHeader.show = value ?? !columnHeader.show
    if (recalculate) columns.calculateColumns()
    return columnHeader.show
  }

  function toggleExpanded(value?: boolean, recalculate = true) {
    columnHeader.isExpanded = !columnHeader.isExpandable.value || (value ?? !columnHeader.isExpanded)
    // TODO : add code that updates the show property of all children based on showWhenExpanded and showWhenCollapsed
    if (recalculate) columns.calculateColumns()
    return columnHeader.isExpanded
  }

  function setColumnCount(count: number) {
    return (columnHeader.columnCount = count)
  }

  function toggleShowWhenExpanded(value?: boolean, recalculate = true) {
    columnHeader.showWhenExpanded = value ?? !columnHeader.showWhenExpanded
    if (recalculate) columns.calculateColumns()
    return columnHeader.showWhenExpanded
  }

  function toggleShowWhenCollapsed(value?: boolean, recalculate = true) {
    columnHeader.showWhenCollapsed = value ?? !columnHeader.showWhenCollapsed
    if (recalculate) columns.calculateColumns()
    return columnHeader.showWhenCollapsed
  }

  function findRightmostVisible(currentColumnHeader = columnHeader) {
    if (!currentColumnHeader.visibleOrDefaultCollapsed.length) return currentColumnHeader
    return findRightmostVisible(currentColumnHeader.visibleOrDefaultCollapsed.at(-1))
  }

  /**
   * Get the value of a cell for a specific column
   * @param labelPathArray - An array of keys representing the path to the data value in the item
   */
  function getCell(dataId: string | number) {
    let data: IGridCellData = state.dataMap.get(dataId) ?? {}
    for (const key of columnHeader.labelPathArray) {
      if (data instanceof Object && !(data instanceof Date)) {
        data = data?.[key]
      }
    }
    return data
  }

  return readonly(columnHeader) as Readonly<IGridHeader>
}
