<template>
  <div class="morningstar-container flex flex-col items-center justify-center" v-if="morningstarRating">
    <div class="flex items-center space-x-2">
      <div v-if="options.showLabel">
        <span class="morningstar-label flex items-center justify-center">
          {{ translate('morningstar_rating') || 'morningstar_rating' }}
        </span>
      </div>
      <div>
        <div class="flex items-center justify-center space-x-1 first:pl-[1px]">
          <span v-for="i in morningstarRating" :key="i">
            <svg
              :class="`h-${getVariant(options.variant).starSize} w-${getVariant(options.variant).starSize} text-primary fill-current`"
              viewBox="0 0 13 13"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.97.843l1.466 4.418 4.655.028-3.75 2.76 1.412 4.435L6.97 9.771l-3.782 2.713 1.41-4.436L.852 5.29l4.654-.028z"
                fill-rule="evenodd"
                class="morningstar-star"
              />
            </svg>
          </span>
        </div>
        <div class="mt-2 flex items-center justify-center pl-[6px]" v-if="options.showLogo">
          <img
            :class="`h-${getVariant(options.variant).starSize}`"
            src="../../../assets/images/Morningstar_Logo.svg"
            alt="Morningstar Logo"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-else class="item-centers flex justify-center">
    <span class="text-black">{{ translate('no_morningstar_rating') || 'no_morningstar_rating' }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { morningstar as story } from './stories'

const { data, options, context } = defineProps(['data', 'options', 'context'])

function translate(value: string) {
  return context.translations[value] || value
}

const morningstarRating = computed(() => {
  if (!data?.morningstar_rating) {
    return null
  } else {
    return +data?.morningstar_rating
  }
})

// hard code data waiting for component page
const variants = {
  small: {
    starSize: 2,
  },
  medium: {
    starSize: 4,
  },
  large: {
    starSize: 6,
  },
}

// fallback to default shape if size not loaded
function getVariant(variant: string) {
  return variants[variant] || variants['medium']
}
</script>
<script lang="ts">
export default {
  api: {
    variant: {
      label: 'variant',
      default: () => 'medium',
      select: () => {
        return ['small', 'medium', 'large']
      },
      attrs: {
        type: 'text',
      },
    },
    showLogo: {
      label: 'Show Morningstar logo',
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
      default: () => true,
    },
    showLabel: {
      label: 'Show Label',
      default: () => false,
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
    },
  },
  styles: {
    '.morningstar-container': {
      name: 'Morningstar Container',
      css: '',
    },
    '.morningstar-label': {
      name: 'Label',
      css: ' color: #000000; font-weight: bold',
    },
    '.morningstar-star': {
      name: 'Star',
      css: 'fill: #000000',
    },
  },
  story,
}
</script>
