import { ContentItem } from './ContentItemService'
import { RestService } from './RestService'

export type ContentFolder = {
  id: number
  type: string
  name: string
  specialtyName?: string
  variables?: string[]
  readOnly: boolean
  contentItems: ContentItem[]
}

export class ContentFolderService extends RestService {
  baseRoute: string

  constructor() {
    super()
    this.baseRoute = 'content-folders'
  }

  async getById({ id }: { id: number }): Promise<ContentFolder> {
    return this.get(`${this.baseRoute}/${id}`)
  }

  async findManyBySpecialtyName({ specialtyName }: { specialtyName: string }): Promise<ContentFolder[]> {
    return this.get(`${this.baseRoute}/by-specialty-name`, {
      params: {
        specialtyName,
      },
    })
  }

  async findManyByType({ type }: { type: string }): Promise<ContentFolder[]> {
    return this.get(`${this.baseRoute}/by-type`, {
      params: {
        type,
      },
    })
  }

  async create({ contentFolderInput }: { contentFolderInput: any }): Promise<ContentFolder> {
    return this.post(this.baseRoute, contentFolderInput)
  }

  async deleteOne({ id }: { id: number }): Promise<ContentFolder> {
    return this.delete(this.baseRoute, id)
  }
}

const contentFolderService = new ContentFolderService()
export default contentFolderService
