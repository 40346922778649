import { RestService } from './RestService'

export type Image = {
  id: number
  path: string
  variables: Record<string, string>
}

export class ImageService extends RestService {
  baseRoute: string

  constructor() {
    super()
    this.baseRoute = 'images'
  }

  async create({ imageInput }: { imageInput: any }): Promise<Image> {
    return this.post(this.baseRoute, imageInput)
  }

  async update({ id, imageInput }: { id: number; imageInput: any }): Promise<Image> {
    return this.put(this.baseRoute, id, imageInput)
  }

  async deleteOne({ id }: { id: number }): Promise<Image> {
    return this.delete(this.baseRoute, id)
  }
}

const imageService = new ImageService()
export default imageService
