<style scoped>
h1 .search-filters {
  display: flex;
  flex: 1;
  margin-left: 10px;
}
h1 .search-filters .autocomplete {
  min-width: 90% !important;
}

h2 input {
  opacity: 0.6;
  margin: 0;
  width: 400px;
}
.first-line .cell.select {
  height: 18px;
}
.first-line .select-all {
  left: -2px;
}
.spreadsheet + .row {
  margin-top: auto !important;
}
:deep() .cell > * {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: fit-content;
}
.block .row:last-child .ghost {
  margin-right: 16px;
}
.cell.cell-details {
  left: 0 !important;
}
.block .spr.spreadsheet-wrapper {
  max-height: 75vh;
}
.row .file_icon {
  margin-left: 4px;
  margin-bottom: -40px;
  max-width: 50px;
  max-height: 40px;
}

.icon-button {
  height: 40px;
  width: 40px;
  padding: 12px;
}

.container-body {
  margin-top: 38px;
}

.container-body .tab-row {
  position: absolute;
  top: -39px;
  min-width: 75%;
}

.container-body .tab-row .tab {
  margin-right: 5px !important;
  cursor: pointer;
  text-align: center;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  max-height: 40px;
  height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  color: var(--colors-inactive);
  border-bottom: 1px solid var(--colors-gray1);
}

.container-body .tab-row .selected-tab {
  border-bottom: 1px solid var(--colors-primary-default);
  color: var(--color-text);
}

.column .data-report-label {
  padding: 12px 0px 0px 0px;
  height: 0px;
}

.row.bottom-buttons .column {
  padding-left: 10px;
}

.row.bottom-buttons .row {
  margin-left: auto;
  padding-bottom: 10px;
}
</style>

<template lang="pug">
transition(@leave='leave')
  loader(v-if="isLoading")
h1
  .row
    span(style="margin-right: 8px") {{ t[$root.screen.path] }}
    badge(:count="reports.length")
  .search-filters
    autocomplete.right(
      :data="reports.reduce((acc, v) => (report_metadata.columns.filter(k => k && typeof v[k] === 'string').map(k => (acc[k] = acc[k] || {}, acc[k][v[k]] =  k === 'schedule' ? cron2def[v[k]] :  v[k])), acc), {})"
      :options="{ placeholder: t.search }"
      :modelValue="$root.filters.filter((v, k) => k !== 'domain' && k !== 'year').reduce((acc, v, k) => (v && v.map(v => acc.push([k, v].join('.'))), acc), [])"
      @update:modelValue="$root.$router.push({ query: Object.assign($root.query.filter((v, k) => !$root.filters.keys().toggle('domain').includes(k)), $event.map(v => v.split('.')).group('0').map(g => g.map('1').join('|'))) })")
subtitle(style="font-style: italic; margin-top: -5px;") {{ t['client-edition-reports-subtitle'] }}
.block.expand.container-body(v-if="!$root.query.report")
  .row.center.left.tab-row
    .block.tab(role="tab" v-for="specialtyName in Object.keys(pressBySpecialty)" @click="()=>changeSpecialty(specialtyName)" :class="{'selected-tab': specialtySelected ? specialtySelected === specialtyName : specialtyName === 'undefined'}") {{specialtyName === 'undefined' ? t.configList.specialtyTab.oldPress : t.configList.specialtyTab.specialtyName || specialtyName}}
  .row.center.left(v-if="selected.length")
    template(v-if="!specialtySelected")
      input(type="text" v-model="launch_date")
      select(@change="onChange($event)" v-model="period")
        option(:value="'weekly'") {{ t['weekly'] || 'weekly'.titleize() }}
        option(:value="'bimonthly'") {{ t['bimonthly'] || 'bimonthly'.titleize() }}
        option(:value="'monthly'") {{ t['monthly'] || 'monthly'.titleize() }}
        option(:value="'quarterly'") {{ t['quarterly'] || 'quarterly'.titleize() }}
        option(:value="'biannual'") {{ t['biannual'] || 'biannual'.titleize() }}
        option(:value="'yearly'") {{ t['yearly'] || 'yearly'.titleize() }}
    button.ghost.icon-button(tt="Launch selected" @click="updateShowPopupRunParameters")
      svg-icon(name="pt-icon-play")
    button.ghost.icon-button(tt="Delete selected" @click="confirmRemove=true")
      svg-icon(name="pt-icon-trash")
  spreadsheet.stripped(:data="filtered_reports" :options="spreadsheetOptions" :key="spreadsheetOptions.key")
    template(v-slot:header-check)
      input(type="checkbox" :checked="selected.length === filtered_reports.length" v-indeterminate="selected.length > 0 && selected.length !== filtered_reports.length" @click="selected = selected.length === filtered_reports.length ? [] : filtered_reports.map('id')")
    template(v-slot:cell-check="s")
      input(type="checkbox" :checked="selected.includes(s.line.id)" @click.stop="selected = selected.map().toggle(s.line.id)" @mousedown.prevent.stop="")
    template(v-slot:cell-patterns="s")
      div {{s.line.patterns.join(', ')}}
    template(v-slot:cell-workflow-name="s")
      div {{ s.line.workflow }}
    template(v-slot:cell-schedule="s")
      div(v-if="s.line.tid !== null") {{ cron2def[s.line.schedule] }}
    template(v-slot:cell-template="s")
      div(v-if="s.line.template") {{ s.line.template }}
      div(v-else style="color: red;") {{t.configList.spreadsheet.templateRemoved}}
    template(v-slot:cell-asof="s")
      input(style="max-width: 100px;" :value="$root.query.domain || new Date().minus('1 month').end('month').format()" @change="uquery({'domain': $event.target.value })")
    template(v-slot:cell-code_mg_share="s")
      div(v-if="s.line.context.code_mg_share !== null") {{ s.line.context.code_mg_share }}
    template(v-slot:cell-fund_name="s")
      div {{ s.line.context.fund_name }}
    template(v-slot:cell-fund_id="s")
      div {{ s.line.context.fund_id?.titleize() }}
    template(v-slot:cell-language="s")
      .tt(style="display: flex;" :tt="s.line.language.upper()")
        svg-icon(class="icon" :name="'flag-' + s.line.language.lower().slice(0, 2)")
    template(v-slot:cell-jurisdiction="s")
      .tt(style="display: flex;" :tt="s.line.language.upper()")
        svg-icon(class="icon" :name="'flag-' + s.line.language.lower().slice(-2)")
    template(v-slot:cell-format="s")
      .tt(style="display: flex;" :tt="s.line.format.upper()")
        ui-asset.file_icon(:name="'icon_' + s.line.format.lower()")
    template(v-slot:cell-account="s")
      div {{ s.line.account || '-' }}
    template(v-slot:cell-account-type="s")
      div {{ t[s.line.account_type] || s.line.account_type }}
    template(v-slot:cell-regional="s")
      div {{ t[s.line.regional] || s.line.regional }}
  .row.bottom-buttons
    .column
      button.primary(@click="update_query({ report: 'Report' })") {{ t.create_report }}
    .row
      .column
        button.primary(@click="upload_report") {{ t.upload || 'Upload' }}
          input.hidden(type="file" ref="fileInput" accept=".xlsx" @change="uploadConfigReport")
      .column
        button.primary(@click="downloadConfigReport()") {{ t.download || 'Download' }}
  .total(style="margin-left: auto;") {{ t.total_report + filtered_reports.length }}
.block.expand(key="form" v-else)
  h2
  .form.expand
    .column
      label {{ t.stamping_pattern || 'stamping_pattern' }}
      autocomplete(:data="stamping_pattern_autocomplete" v-model="createReportForm.patterns")
      label {{ t.workflow }}
      select(v-model="createReportForm.workflowId")
        option
        option(:value="workflow.id" v-for="workflow in workflows.v().filter(w => w && !w.disabled)") {{ workflow.name }}
    .column
      label {{ t.schedule }}
      select(v-model="createReportForm.schedule")
        option
        option(value="0 0 * * *") Daily
        option(value="0 0 * * 0") Weekly
        option(value="0 0 1,15 * *") Bimonthly
        option(value="0 0 1 * *") Monthly
        option(value="0 0 1 3,6,9,12 *") Quarterly
        option(value="0 0 1 6,12 *") Semi-Annual
        option(value="0 0 1 1 *") Yearly
    .column
      label {{ t.template }}
      .row
        select(v-model="createReportForm.template" @change="updateSelectedTypes")
          option
          <optgroup v-for="(typeList, type) in Object.values(templates).group('type')" :label="type">
            option(:value="template.name" v-for="template in typeList") {{ template.name }} {{ type }}
          </optgroup>
        ui-asset.file_icon(v-if="selectedType" :name="getSelectedType")
        .column.data-report-label(v-if="getDataReportName" ) {{t.configList.create.dataReport}} {{getDataReportName}}
    template(v-if="isOldClient && !getDataReportName && createReportForm.template")
      .column
        label {{ t.funds }}
        autocomplete(:data="sharesAutocomplete" v-model="createReportForm.isin")
      .column
        label {{ t.lang + ' / ' + t.jurisdiction }}
        .row.left
          label.row.center.left(:tt="lang.upper()" v-for="lang in langs")
            input(style="height: 16px;margin: 6px;" type="radio" :value="lang" v-model="createReportForm.lang")
            svg-icon(class="icon" :name="'flag-' + (lang.lower().slice(0, 2))")
            span.space {{ lang.upper() }}
    .column(v-if="!isOldClient || getDataReportName")
      dr-variable-input(
        v-if="getDataReportName"
        v-for="partial in drPartials"
        :variableDefinition="partial"
        :inputData="drInputData?.[partial.name]"
        :missingDependencies="drMissingDependencies[partial.name]"
        :value="drVariables[partial.name]"
        @update:value="value => drUpdateVariable(partial, value)"
        :key="partial.name"
        )
  .row
    button.primary.ghost(@click="$router.push('reports')") {{ t.cancel }}
    button.primary.ghost(@click="createReport") {{ t.create_report }}
    button.primary(@click="reset_form") {{t.configList.create.button.clear}}
popup(:show="confirmRemove")
  template(v-slot:header)
    | {{t["confirmation_remove"]}}
  template(v-slot:content)
    | {{t["text_remove"]}}
  template(v-slot:action)
    button.secondary-action(@click="confirmRemove = false") {{t['confirmation_btn_close']}}
    button.main-action(@click="delete_selected(selected)") {{t["confirmation_btn_delete"]}}
popup(:show="showPopupRunParameters")
  template(v-slot:header)
    | {{t.configList.runParameter.header}}
  template(v-slot:content)
    dr-variable-input(
      v-for="runParameter in drRunParameters"
      :variableDefinition="runParameter"
      :inputData="drInputData?.[runParameter.name]"
      :missingDependencies="drMissingDependencies[runParameter.name]"
      :validationError="drValidationErrors[runParameter.name]"
      :value="drVariables[runParameter.name]"
      @update:value="value => drUpdateVariable(runParameter, value)"
      :key="runParameter.name"
    )
  template(v-slot:action)
    button.secondary-action(@click="()=>{showPopupRunParameters = false;}") {{t['confirmation_btn_close']}}
    button.main-action(:disabled="isFormDisabled" @click="updateShowPopupRunParameters") {{t.configList.runParameter.button.validate}}
</template>

<script>
import * as XLSX from 'xlsx'
import { useWorkflows } from '../composables/useWorkflows'
import { usePresses } from '../composables/usePresses'
import useRunId from '../composables/useRunId'
import { useTemplates } from '../composables/useTemplates'
import { computed, ref, watch } from 'vue'
import { useConfig } from '../composables/useConfig'
import { useProgress } from '../composables/useProgress'
import { useShares } from '../composables/useShares'
import { useLangs } from '../composables/useInitializeLangs'
import templateService from '../../../services/TemplateService'
import Popup from '../../../components/ui/popup.vue'
import dataReportService from '@100-m/hauru/src/services/DataReportService'
import DrVariableInput from '@100-m/hauru/src/components/form/drVariables/drVariableInput.vue'
import { useDrVariables } from '@100-m/hauru/src/components/form/drVariables/lib'
import { commandrSDK } from '@100-m/hauru/src/features/commandr'
import {
  importConfigReport,
  exportConfigReport,
} from '@100-m/hauru/src/applications/client-edition/utils/reportFileHandlers.js'

export const additions = { icon: 'ic_storage' }
export default {
  components: { DrVariableInput, Popup },
  setup() {
    const drLoaded = ref(false)
    const { workflows, loaded: wfLoaded } = useWorkflows()
    const { presses, loaded: prLoaded, initialized } = usePresses()
    const { shares, fund_context, loaded: sLoaded } = useShares(false, false, $root.config.extra_share_characteristics)
    const { genRunId } = useRunId()
    const { templates, loaded: tplLoaded } = useTemplates()
    const { config, define_scope_fn, loaded: confLoaded } = useConfig()
    const { progress } = useProgress([wfLoaded, prLoaded, tplLoaded, confLoaded, sLoaded, drLoaded])
    const selectedType = ref('')
    const { langs } = useLangs()
    const {
      variables: drVariables,
      updateVariable: drUpdateVariable,
      partials: drPartials,
      inputData: drInputData,
      missingDependencies: drMissingDependencies,
      initDrVariables,
      runParameters: drRunParameters,
      validationErrors: drValidationErrors,
    } = useDrVariables()

    // Initial loading
    const isLoading = computed(
      () =>
        !wfLoaded.value ||
        !prLoaded.value ||
        !tplLoaded.value ||
        !confLoaded.value ||
        !sLoaded.value ||
        !initialized.value ||
        !drLoaded.value,
    )

    const dataReports = ref([])
    const loadDataReports = async () => {
      const drIds = Object.values(templates.value)
        .map(t => t?.dataReportId)
        .filter(e => e)
      dataReports.value = await dataReportService.getByIds(drIds)
      drLoaded.value = true
    }
    watch(templates, loadDataReports)

    const pressBySpecialty = ref({})
    const specialtySelected = ref()
    const groupPressBySpecialty = () => {
      pressBySpecialty.value = presses.value.filter(p => !p.disabled).group('specialtyName')
      if (!specialtySelected.value) {
        const firstSpecialtyGroupName = Object.keys(pressBySpecialty.value)[0]
        specialtySelected.value = firstSpecialtyGroupName === 'undefined' ? undefined : firstSpecialtyGroupName
      }
    }
    watch(presses, groupPressBySpecialty)

    const templateNames = computed(() => Object.keys(templates.value))

    const sharesAutocomplete = computed(() => {
      return shares.value
        .map(s => ({
          id: s.share_id,
          name: [s.fund_name, s.characterstics?.share_letter || '', `(${s.share_id})`].filter(s => s).join(' - '),
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
        .reduce((acc, s) => {
          acc[s.id] = s.name
          return acc
        }, {})
    })

    return {
      workflows,
      presses,
      shares,
      genRunId,
      fund_context,
      templates,
      templateNames,
      config,
      define_scope_fn,
      isLoading,
      progress,
      sharesAutocomplete,
      selectedType,
      dataReports,
      drVariables,
      drUpdateVariable,
      drPartials,
      drInputData,
      drMissingDependencies,
      drValidationErrors,
      initDrVariables,
      drRunParameters,
      pressBySpecialty,
      specialtySelected,
      // for old clients
      langs,
    }
  },
  data() {
    return {
      confirmRemove: false,
      selected: [],
      period: 'monthly',
      launch_date: $root.query.domain || new Date().minus('1 month').end('month').format().slice(0, 7),
      createReportForm: {
        name: 'Report',
        schedule: '',
        workflowId: null,
        template: '',
        patterns: [],
        //for old clients
        isin: null,
        lang: '',
      },
      showPopupRunParameters: false,
    }
  },
  methods: {
    onChange(event) {
      this.period = event.target.value
      switch (event.target.value) {
        case 'weekly':
          this.launch_date = new Date().minus('7 days').format() + '|' + new Date().format()
          break
        case 'bimonthly':
          this.launch_date = new Date().minus('14 days').format() + '|' + new Date().format()
          break
        case 'monthly':
          this.launch_date = new Date().minus('1 month').end('month').format().slice(0, 7)
          break
        case 'quarterly':
          this.launch_date =
            new Date().minus('3 month').end('month').format().slice(0, 7) +
            '|' +
            new Date().minus('1 month').end('month').format().slice(0, 7)
          break
        case 'biannual':
          this.launch_date =
            new Date().minus('6 month').end('month').format().slice(0, 7) +
            '|' +
            new Date().minus('1 month').end('month').format().slice(0, 7)
          break
        case 'yearly':
          this.launch_date =
            new Date().minus('1 month').start('years').format('YYYY-MM') +
            '|' +
            new Date().minus('1 month').end('month').format('YYYY-MM')
          break
        default:
          this.launch_date = new Date().minus('1 month').end('month').format().slice(0, 7)
          break
      }
    },
    changeSpecialty(specialtyName) {
      this.specialtySelected = specialtyName === 'undefined' ? undefined : specialtyName
      this.selected = []
    },
    define_asof(launch_date, period) {
      try {
        if (!this.config.reporting_asof) return '' // ASOF not enabled
        switch (period) {
          case 'weekly':
            return launch_date.split('|')[1]
          case 'bimonthly':
            return launch_date.split('|')[1]
          case 'monthly':
            return new Date(launch_date).end('month').format()
          case 'quarterly':
            return new Date(launch_date.split('|')[1]).end('month').format()
          case 'biannual':
            return new Date(launch_date.split('|')[1]).end('month').format()
          case 'yearly':
            return new Date(launch_date.split('|')[1]).end('month').format()
          default:
            return ''
        }
      } catch (error) {
        console.error(launch_date, period, error)
        $root.toast({ description: 'error_launch_press', type: 'error', timeout: 5000 })
      }
    },
    async launch_selected(lines) {
      const runParameters = this.drVariables
      const presses = lines.map(id => this.presses.find(e => e.id === id)).filter()
      if (presses.find(l => !this.workflows.find(w => w.id === l.workflow_id))) {
        console.log('this.workflows', this.workflows, presses)
        return $root.toast({ description: 'Workflow is not valid', type: 'error', timeout: 5000 })
      }
      if (presses.find(l => !this.templateNames.find(t => t === l.template)))
        return $root.toast({ description: 'Template is not valid', type: 'error', timeout: 5000 })

      const date = this.specialtyName ? runParameters.domain : this.launch_date
      if (date && !date.includes('|') && !date.includes('Q') && isNaN(new Date(date || 0)))
        return $root.toast({ description: 'Date is not valid', type: 'error', timeout: 5000 })

      const new_runs = {}

      for (const line of presses) {
        const launchPress = await this.launch_press(line, runParameters)
        new_runs[launchPress.id] = launchPress
      }

      update('data.runs', new_runs)
      const years = new_runs
        .map(r => r.year)
        .reduce((acc, m) => {
          acc[m] = true
          return acc
        }, {})
      update('data.runs_years', years)
      $root.toast({ description: 'report_launched', type: 'success', timeout: 5000 })
    },
    async launch_press(line, runParameters) {
      const templatesWithName = await templateService.findManyByName({ name: line.template })
      const templateBuilder = templatesWithName?.[0]

      const date = this.specialtyName ? runParameters.domain : this.launch_date
      const year = date.split('|')[0].split('-')[0]
      const apiBaseUrl = config.graphqlEndpoint.includes('localhost')
        ? config.graphqlEndpoint
        : `${window.location.protocol}//${window.location.host}${config.graphqlEndpoint}`

      let context = {
        format: line.format,
        patterns: line.patterns.map(d => this.stamping_pattern[d]), // commandr need pattern function
        patterns_name: line.patterns.join(','), // we want to see pattern name in fonds screens
        template: line.template,
        title: '-',
        account: '-',
        dataReportId: templateBuilder?.dataReportId || null,
        templateId: templateBuilder?.id || null,
        dqcReportId: templateBuilder?.dataQualityCheckReportId || null,
        apiBaseUrl,
        owner: $root?.profile?.email,
        run_time: new Date().toISOString(),
        period: this.period,
      }
      if (this.specialtySelected) {
        const dataReportId = this.templates[line.template]?.dataReportId
        const specialtyVariables = this.dataReports.find(dr => dr.id === dataReportId)?.settingVariableParameters
        await this.initDrVariables(specialtyVariables, { ...runParameters, ...line })
        const drVariables = await this.drVariables

        context = {
          ...drVariables,
          ...context,
          date: drVariables.domain,
          lang: drVariables.language,
        }
      } else {
        const share = this.shares.find(s => s.share_id === `${line.isin}`)
        if (!share) throw new Error('Share not found')

        // specialty by fund, get fund context, if by share override with share context this.fund_context[line.isin]
        const fundContext = (Object.values(this.fund_context || {}) || []).find(d => d.fundId === line.fundId)

        context = {
          ...fundContext,
          ...this.fund_context[line.isin],
          ...context,
          period: this.period,
          asof: this.define_asof(this.launch_date, this.period),
          endDate: new Date(this.launch_date).end('month').format('YYYY-MM-DD'), // To be removed (used for find datareport variable in context), it will bug if last day of month is no data datay
          isin: line.isin,
          shareId: line.isinShare || line.isin, // To be removed (used for find datareport variable in context)
          date: this.launch_date,
          domain: this.launch_date,
          closing_date: this.launch_date.includes('|') ? this.launch_date.split('|')[1] : this.launch_date, // If I want synchro a quarterly and a monthly workflow (Amiral case)
          language: line.language,
          lang: line.language, // To be removed (used for find datareport variable in context)
        }
      }
      if (templateBuilder?.layout?.path) {
        // case of an Excel report
        context.excelTemplateFile = templateBuilder.layout.path

        const varParams = encodeURIComponent(
          JSON.stringify({
            fundId: context.fundId,
            isin: context.isin || context.shareId,
            domain: context.domain,
            date: context.date,
            asof: context.asof,
            language: context.language,
          }),
        )

        const baseUrl = config.graphqlEndpoint.match(/http:\/\/|https:\/\/|localhost/)
          ? ''
          : `${window.location.protocol}//${window.location.host}`

        context.data_report_url = `${baseUrl}${config.graphqlEndpoint}/data-reports/${context.dataReportId}/run?runVariables=${varParams}`
      } else {
        // not an Excel report, so templateBuilder.layout.theme is defined and the landscape key makes sense
        context.landscape = templateBuilder?.layout?.theme?.page_orientation
          ? templateBuilder.layout.theme.page_orientation === 'landscape'
          : this.templates[line.template].theme.includes('pdf-page landscape')
      }
      const id = await this.genRunId()
      const log = { id: 1, status: 'queued', action_id: 1 }
      return {
        context,
        id,
        year,
        press: line,
        workflow: this.workflows.find(w => w.id === line.workflow_id) || {},
        logs: { 1: log },
      }
    },
    delete_selected(lines) {
      const presses = this.presses.map(presse => {
        if (lines.includes(presse.id)) {
          presse.disabled = true
        }
        return presse
      })
      set('data.presses', presses)
      this.confirmRemove = false
    },
    is_press_exist(existing_presses, newPress) {
      return existing_presses.some(
        press =>
          !press.disabled &&
          newPress.name === press.name &&
          // patterns === press.pattern && ???
          String(newPress.workflow_id) === String(press.workflow_id) &&
          newPress.schedule === press.schedule &&
          String(newPress.template_id) === String(press.template_id) &&
          newPress.template === press.template &&
          newPress.fundId === press.fundId &&
          newPress.shareId === press.shareId &&
          newPress.isin === press.isin &&
          newPress.language === press.language &&
          newPress.format === press.format &&
          newPress.selectedType === press.selectedType,
      )
    },
    createReport() {
      const { name, patterns, workflowId, schedule, template } = this.createReportForm
      if (!this.isFilled(this.createReportForm) || !this.selectedType)
        return $root.toast({ description: 'missing_mandatory_fields', type: 'error', timeout: 5000 })

      const existing_presses = this.presses || []
      const format = this.selectedType

      let partials = this.drPartials.reduce((acc, p) => {
        acc[p.name] = this.drVariables[p.name]
        return acc
      }, {})

      const specialtyName = this.dataReports.find(
        dr => dr.id === this.templates[this.createReportForm.template]?.dataReportId,
      )?.specialtyName

      if (this.isOldClient && !specialtyName) {
        const context = this.fund_context[this.createReportForm.isin]
        partials = {
          fund_id: context.fund_id,
          lang: this.createReportForm.lang,
          language: this.createReportForm.lang,
          isin: this.createReportForm.isin,
          specialtyName: null,
        }
      }

      const newPress = {
        format,
        id: (existing_presses.v().last() || {}).id + 1 || 0, // existing_presses.v().last()?.id || 0
        name,
        schedule,
        template,
        workflow_id: +workflowId,
        patterns: patterns.map(p => this.stamping_pattern.find(stamping => stamping === p)).filter(),
        specialtyName,
        ...partials,
      }
      if (this.isOldClient && !newPress.specialtyName) delete newPress.specialtyName
      if (this.is_press_exist(existing_presses, newPress))
        return $root.toast({
          description: 'A report already exists with these parameters',
          type: 'error',
          timeout: 5000,
        })

      existing_presses.push(newPress)

      set('data.presses', existing_presses) // todo optimise for add only the new press and not all press
      this.reset_form()

      $root.toast({ description: $root.t.config_created, type: 'success', timeout: 5000 })

      this.$router.push('reports')
    },
    reset_form() {
      this.createReportForm = {
        name: 'Report',
        schedule: '',
        workflowId: null,
        template: '',
        patterns: [],
        //for old clients
        isin: null,
        lang: '',
      }
      this.initDrVariables([])
      this.selectedType = null
    },
    isFilled(formData = {}) {
      // NOTE(wassim): maybe change the predicate to a binary operation
      // https://stackoverflow.com/questions/2641347/short-circuit-array-foreach-like-calling-break

      const specialtyName = this.dataReports.find(
        dr => dr.id === this.templates[this.createReportForm.template]?.dataReportId,
      )?.specialtyName

      if (specialtyName) {
        delete formData.isin
        delete formData.lang
      }

      const nonFilledFormData = Object.keys(formData).some(key => {
        // null, undefined or empty array or string
        const value = formData[key]
        if (value === null || value === undefined) return true
        if (typeof value === 'string' && value === '') return true
        if (Array.isArray(value) && value.length === 0) return true
        // empty object
        if (!Array.isArray(value) && typeof value === 'object') return this.isFilled(value)
      })

      if (this.isOldClient && !specialtyName) {
        return !nonFilledFormData
      }

      const nonFilledPartial = this.drPartials.some(partial => {
        const value = this.drVariables[partial.name]
        return !value
      })

      return !nonFilledFormData && !nonFilledPartial
    },
    updateSelectedTypes() {
      const groupedTemplates = Object.values(this.templates).group('type')
      for (let [type, typeList] of Object.entries(groupedTemplates)) {
        let selectedTemplate = typeList.find(template => template.name === this.createReportForm.template)
        if (selectedTemplate) {
          this.selectedType = type

          const dataReportVariableSettings = this.dataReports.find(
            dr => dr.id === this.templates[this.createReportForm.template]?.dataReportId,
          )?.settingVariableParameters
          if (dataReportVariableSettings) this.initDrVariables(dataReportVariableSettings)
          return
        }
      }
      this.selectedType = null
    },
    updateShowPopupRunParameters() {
      if (!this.showPopupRunParameters && this.specialtySelected) {
        const templateName = this.pressBySpecialty[this.specialtySelected]?.[0]?.template
        const dataReportId = this.templates[templateName]?.dataReportId
        const specialtyVariables = this.dataReports.find(dr => dr.id === dataReportId)?.settingVariableParameters
        this.initDrVariables(
          specialtyVariables?.filter(v => ['domainFreeText', 'langList', 'fundId', 'shareId'].includes(v.variableType)),
        )
      } else {
        this.launch_selected(this.selected)
      }

      if (this.specialtySelected) {
        this.showPopupRunParameters = !this.showPopupRunParameters
      }
    },
    upload_report() {
      this.$refs.fileInput.click()
    },
    uploadConfigReport(event) {
      const specialtyNames = new Set(this.dataReports.map(item => item.specialtyName))
      const templatesNames = Object.keys(this.templates)
      const patternsNames = Object.keys(this.stamping_pattern)
      importConfigReport(
        event,
        this.cron2def,
        this.workflows,
        this.$root.toast,
        commandrSDK,
        Object.values(this.pressBySpecialty).flat(),
        specialtyNames,
        templatesNames,
        patternsNames,
      )
    },
    downloadConfigReport() {
      exportConfigReport(Object.values(this.pressBySpecialty).flat(), this.cron2def, this.$root.toast)
    },
  },
  computed: {
    isOldClient() {
      return ['mandarine', 'lafrancaise'].includes(import.meta.env.VITE_PROJECT)
    },
    stamping_pattern() {
      let default_pattern = {
        default_by_fund: 'docs/[date]/[fundId]-[date]-[language].[format]',
        default_by_share: 'docs/[date]/[fundId]-[shareId]-[date]-[language].[format]',
      }
      if (this.isOldClient) {
        default_pattern = {
          default: 'docs/[date]/[fund_id]-[isin]-[date]-[language].[format]',
        }
      }

      return {
        ...this.config.stamping_pattern, // from firebase (depracted)
        ...$root.config.stamping_pattern, // from module.js in client config code layer
        ...default_pattern,
      }
    },
    reports() {
      if (!this.specialtySelected) {
        return (this.pressBySpecialty[undefined] || {})
          .map(d =>
            Object.assign(
              {
                ...this.fund_context[d.isin],
                workflow: (this.workflows.find(e => e.id === d.workflow_id) || {}).name,
              },
              d,
            ),
          )
          .filter(d => !d.disabled)
          .map(report => {
            return {
              ...report,
              jurisdiction: report.language.slice(-2),
              scope: this.define_scope_fn(report),
            }
          })
      } else {
        return (this.pressBySpecialty[this.specialtySelected] || {})
          .map(d =>
            Object.assign(
              {
                workflow: (this.workflows.find(e => e.id === d.workflow_id) || {}).name,
              },
              d,
            ),
          )
          .filter(d => !d.disabled)
          .filter(d => d.specialtyName === this.specialtySelected)
          .map(report => {
            return {
              ...report,
              scope: this.define_scope_fn(report),
            }
          })
      }
    },
    filtered_reports() {
      const authorized_filter = this.spreadsheetOptions.columns || [
        'name',
        'template',
        'workflow',
        'fund_id',
        'language',
        'format',
      ]
      const filters = Object.entries($root.query)
        .filter(([k, v]) => !['domain', 'search', 'selected', 'id'].includes(k))
        .filter(d => authorized_filter.includes(d[0]))
        .map(([k, v]) => [k, v.split('|')])
      return this.reports
        .filter(d => !d.disabled)
        .filter(d =>
          filters.every(([k, vs]) =>
            vs.some(
              v =>
                d[k] === v ||
                (v.slice(0, 1) === '>' && d[k] > v.slice(1)) ||
                (v.slice(0, 1) === '<' && d[k] < v.slice(1)),
            ),
          ),
        )
    },
    stamping_pattern_autocomplete() {
      // HACK due to stange autocomplete behaviour
      return this.stamping_pattern.reduce((acc, v, k) => {
        acc[v] = k
        return acc
      }, {})
    },
    getSelectedType() {
      return 'icon_' + (this.selectedType ? this.selectedType.toLowerCase() : '')
    },
    getDataReportName() {
      return (
        this.dataReports.find(dr => dr.id === this.templates[this.createReportForm.template]?.dataReportId)?.name || ''
      )
    },
    spreadsheetOptions() {
      if (this.specialtySelected) {
        const specialtyVariables = this.dataReports.find(
          dr => dr.specialtyName === this.specialtySelected,
        )?.settingVariableParameters
        let langFilter = []
        if ((specialtyVariables || []).find(v => v.variableType === 'langList')) {
          langFilter = ['language', 'jurisdiction']
        }

        let specialtyFilter = (specialtyVariables || []).reduce((acc, variable) => {
          if (variable.scope === 'partial' && variable.variableType !== 'langList') {
            acc.push(variable.name)
          }
          return acc
        }, [])

        return {
          columns: [
            {
              name: 'check',
              freeze: true,
            },
            'format',
            'template',
            'workflow',
            'schedule',
            ...specialtyFilter,
            ...langFilter,
            'patterns',
          ],
          editable: false,
          key: `report-${this.specialtySelected}`,
          sort: ['pattern'],
        }
      } else {
        return this.report_metadata
      }
    },
    isFormDisabled() {
      return Object.values(this.drValidationErrors).some(error => !!error)
    },
  },
  watch: {
    '$root.query.report'() {
      this.$nextTick(() => scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' }))
    },
  },
}
</script>
