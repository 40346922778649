/* globals window, $root, config */
import { GraphQlService } from './GraphQlService'

export class DataReportService extends GraphQlService {
  constructor() {
    super()
    this.baseRoute = 'data-reports'
  }

  async list() {
    return await (await fetch(`${config.graphqlEndpoint}/${this.baseRoute}?token=${$root?.profile?.idToken}`)).json()
  }

  async getAll() {
    return (
      await this.query(
        `query getAll{
            rawData {
              dataReportDefinitions {
                id
                name
                variables
                settings
                specialtyName
                settingVariableParameters
                DataQualityCheckReport {
                  id
                  name
                }
              }
            }
        }
        `,
      )
    ).data.rawData.dataReportDefinitions
  }

  async getByIds(ids) {
    return (
      await this.query(
        `query getDrByIds($ids: [IntOrString!]!) {
          rawData {
            dataReportDefinitionsByIds(ids: $ids) {
              id
              name
              query
              variables
              settings
              specialtyName
              settingVariableParameters
            }
          }
        }`,
        { ids },
      )
    ).data.rawData.dataReportDefinitionsByIds
  }

  async getByName(name, variables) {
    return (
      await this.query(
        `query getDrByName($name: String!, $variables: JSON) {
          rawData {
            dataReportDefinitionByName(name: $name, variables: $variables) {
              id
              name
              query
              variables
            }
          }
        }`,
        { name, variables },
      )
    ).data.rawData.dataReportDefinitionByName
  }

  async getById(id, variables) {
    return (
      await this.query(
        `query getDrById($id: IntOrString!, $variables: JSON) {
          rawData {
            dataReportDefinitionById(id: $id, variables: $variables) {
              id
              name
              query
              variables
              settings
              specialtyName
              settingVariableParameters
            }
          }
        }`,
        { id, variables },
      )
    ).data.rawData.dataReportDefinitionById
  }

  async run(id, variables, options = { preProcess: false, postProcess: false, forceCalculate: false }) {
    const runVariables = encodeURIComponent(JSON.stringify(variables))

    try {
      const response = await fetch(
        `${config.graphqlEndpoint}/${this.baseRoute}/${id}/run?runVariables=${runVariables}&token=${encodeURI($root?.profile?.idToken)}&preProcess=${encodeURI(options.preProcess)}&postProcess=${encodeURI(options.postProcess)}&forceCalculate=${encodeURI(options.forceCalculate)}`,
      )
      if (!response.ok) {
        const errors = await response.json()
        const errorMessage = Array.isArray(errors) ? errors.map(e => e.message).join('\n') : errors
        throw new Error(`Request failed with status ${response.status}\n${errorMessage}`)
      }

      const data = await response.json()
      return { data, error: null }
    } catch (error) {
      return { data: null, error: error.message || 'Unexpected error' }
    }
  }

  async create({ name, query, variables, specialtyName }) {
    return (
      await this.query(
        `
      mutation create($name: String!, $query: String!, $variables: JSON, $specialtyName: String) {
        addDataReportDefinition(name: $name, query: $query, variables: $variables, specialtyName: $specialtyName) {
          id
        }
      }`,
        { name, query, variables, specialtyName },
      )
    ).data.addDataReportDefinition
  }

  async update(id, { name, query, variables, settings, specialtyName }) {
    if (!variables) variables = {}
    return (
      await this.query(
        `
      mutation update($id: Int!, $name: String, $query: String, $description: String, $variables: JSON, $settings: JSON, $specialtyName: String) {
        updateDataReportDefinition(id: $id, name: $name, query: $query, description: $description, variables: $variables, settings: $settings, specialtyName: $specialtyName) {
          id
        }
      }`,
        { id, name, query, variables, settings, specialtyName },
      )
    ).data.updateDataReportDefinition
  }

  async remove(id) {
    return (
      await this.query(
        `
      mutation remove($id: Int!) {
        deleteDataReportDefinition(id: $id) {
          id
        }
      }`,
        { id },
      )
    ).data.deleteDataReportDefinition
  }
}

const dataReportService = new DataReportService()
export default dataReportService
